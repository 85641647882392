const actions = {
  // generic actions
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  // search actions
  SEARCH: 'SEARCH',
  START_SEARCH: 'START_SEARCH',
  END_SEARCH: 'END_SEARCH',
  // filter actions
  TOGGLE_FILTER_PANEL: 'TOGGLE_FILTER_PANEL',
  // store listing actions
  TOGGLE_STORE_LIST_DIALOG: 'TOGGLE_STORE_LIST_DIALOG',
  // tag actions
  LOAD_TAGS_TRANSACTION: 'LOAD_TAGS_TRANSACTION',
  LOAD_TAGS: 'LOAD_TAGS',
  LOAD_TAGS_FAILED: 'LOAD_TAGS_FAILED',
  LOADING_TAGS: 'LOADING_TAGS',
  TAGS_LOADED: 'TAGS_LOADED',
  SAVE_TAG: 'SAVE_TAG',
  SHOW_SAVED_TAG_MESSAGE: 'SHOW_SAVED_TAG_MESSAGE',
  SAVED_TAG_MESSAGE: 'SAVED_TAG_MESSAGE',
  MOVE_TAG: 'MOVE_TAG',
  UPDATE_TAG: 'UPDATE_TAG',
  SHOW_MENU_EVENT_MESSAGE: 'SHOW_MENU_EVENT_MESSAGE',
  TAG_FOLDER_MESSAGE: 'TAG_FOLDER_MESSAGE',
  MENU_EVENT_MESSAGE: 'MENU_EVENT_MESSAGE',
  MENU_EVENT_TRIGGERING: 'MENU_EVENT_TRIGGERING',
  SET_EDIT_TAG_DATA: 'SET_EDIT_TAG_DATA',
  TAG_ADDED: 'TAG_ADDED',
  TAG_SAVING: 'TAG_SAVING',
  TAG_MOVING: 'TAG_MOVING',
  TAG_MOVED: 'TAG_MOVED',
  TAG_ADD_FAILED: 'TAG_ADD_FAILED',
  TOGGLE_TAG_DIALOG: 'TOGGLE_TAG_DIALOG',
  TOGGLE_EDIT_TAG_DIALOG: 'TOGGLE_EDIT_TAG_DIALOG',
  TAG_UPDATING: 'TAG_UPDATING',
  TAG_UPDATING_FAILED: 'TAG_UPDATING_FAILED',
  DELETE_TAG: 'DELETE_TAG',
  TAG_DELETING: 'TAG_DELETING',
  TAG_DELETE_FAILED: 'TAG_DELETE_FAILED',
  TAG_DELETED: 'TAG_DELETED',
  // folder actions
  LOAD_FOLDERS_TRANSACTION: 'LOAD_FOLDERS_TRANSACTION',
  LOAD_FOLDERS: 'LOAD_FOLDERS',
  LOAD_FOLDERS_FAILED: 'LOAD_FOLDERS_FAILED',
  LOADING_FOLDERS: 'LOADING_FOLDERS',
  FOLDERS_LOADED: 'FOLDERS_LOADED',
  SAVE_FOLDER: 'SAVE_FOLDER',
  MOVE_FOLDER: 'MOVE_FOLDER',
  UPDATE_FOLDER: 'UPDATE_FOLDER',
  SET_EDIT_FOLDER_DATA: 'SET_EDIT_FOLDER_DATA',
  FOLDER_ADDED: 'FOLDER_ADDED',
  FOLDER_SAVING: 'FOLDER_SAVING',
  FOLDER_UPDATING: 'FOLDER_UPDATING',
  FOLDER_UPDATING_FAILED: 'FOLDER_UPDATING_FAILED',
  FOLDER_ADD_FAILED: 'FOLDER_ADD_FAILED',
  TOGGLE_FOLDER_DIALOG: 'TOGGLE_FOLDER_DIALOG',
  TOGGLE_EDIT_FOLDER_DIALOG: 'TOGGLE_EDIT_FOLDER_DIALOG',
  DELETE_FOLDER: 'DELETE_FOLDER',
  FOLDER_DELETING: 'FOLDER_DELETING',
  FOLDER_DELETE_FAILED: 'FOLDER_DELETE_FAILED',
  FOLDER_DELETED: 'FOLDER_DELETED',
  DELETE_FOLDER_TRANSACTION: 'DELETE_FOLDER_TRANSACTION',
  FOLDER_TRANSACTION_DELETING: 'FOLDER_TRANSACTION_DELETING',
  FOLDER_TRANSACTION_DELETE_FAILED: 'FOLDER_TRANSACTION_DELETE_FAILED',
  FOLDER_TRANSACTION_DELETED: 'FOLDER_TRANSACTION_DELETED',
  FOLDER_MOVING: 'FOLDER_MOVING',
  FOLDER_MOVED: 'FOLDER_MOVED',
  TXN_TAG_REMOVED: 'TAG_REMOVED',
  REMOVING_TXN_TAG: 'REMOVING_TXN_TAG',
  TXN_TAG_REMOVE_FAILED: 'TXN_TAG_REMOVE_FAILED',
  REMOVE_TXN_TAG: 'REMOVE_TXN_TAG',
  // LOADING ES BY TYPE ID
  LOAD_ES_BY_TYPE_ID: 'LOAD_ES_BY_TYPE_ID',
  // LOADING ES BY STORE
  LOAD_ES_TRX_BY_STORE: 'LOAD_ES_TRX_BY_STORE',
  LOAD_MORE_ES_TRX: 'LOAD_MORE_ES_TRX',
  LOADING_ES_TRX: 'LOADING_ES_TRX',
  ES_TRX_VALUE: 'ES_TRX_VALUE',
  LOADING_ES_TRX_SUCCESS: 'LOADING_ES_TRX_SUCCESS',
  LOADING_ES_TRX_ERROR: 'LOADING_ES_TRX_ERROR',
  // load transaction events
  LOAD_ALL_TRANSACTION: 'LOAD_ALL_TRANSACTION',
  LOADING_TRANSACTIONS: 'LOADING_TRANSACTIONS',
  LOAD_MORE_TRANSACTIONS: 'LOAD_MORE_TRANSACTIONS',
  LOADING_MORE_TRANSACTIONS: 'LOADING_MORE_TRANSACTIONS',
  TRANSACTIONS_LOADED: 'TRANSACTIONS_LOADED',
  SEARCH_TRANSACTIONS_LOADED: 'SEARCH_TRANSACTIONS_LOADED',
  LOAD_TRANSACTIONS_FAILED: 'LOAD_TRANSACTIONS_FAILED',
  // PENDING TRANSACTION
  LOAD_PENDING_TRANSACTION: 'LOAD_PENDING_TRANSACTION',
  LOADING_PENDING_TRANSACTION: 'LOADING_PENDING_TRANSACTION',
  LOADED_PENDING_TRANSACTION: 'LOADED_PENDING_TRANSACTION',
  LOAD_PENDING_TRANSACTION_FAILED: 'LOAD_PENDING_TRANSACTION_FAILED',
  // REFUNDED TRANSACTION
  LOAD_REFUNDED_TRANSACTION: 'LOAD_REFUNDED_TRANSACTION',
  LOADING_REFUNDED_TRANSACTION: 'LOADING_REFUNDED_TRANSACTION',
  LOADED_REFUNDED_TRANSACTION: 'LOADED_REFUNDED_TRANSACTION',
  LOAD_REFUNDED_TRANSACTION_FAILED: 'LOAD_REFUNDED_TRANSACTION_FAILED',
  // ISSUE REFUND
  ISSUE_REFUND: 'ISSUE_REFUND',
  ISSUING_REFUND: 'ISSUING_REFUND',
  REFUND_ISSUED: 'REFUND_ISSUED',
  ISSUE_REFUND_FAILED: 'ISSUE_REFUND_FAILED',
  REFUND_MESSAGE: 'REFUND_MESSAGE',
  // PAID TRANSACTIONS
  LOAD_PAID_TRANSACTION: 'LOAD_PAID_TRANSACTION',
  LOADING_PAID_TRANSACTION: 'LOADING_PAID_TRANSACTION',
  LOADED_PAID_TRANSACTION: 'LOADED_PAID_TRANSACTION',
  LOAD_PAID_TRANSACTION_FAILED: 'LOAD_PAID_TRANSACTION_FAILED',
  // FAILED TRANSACTION
  LOAD_FAILED_TRANSACTION: 'LOAD_FAILED_TRANSACTION',
  LOADING_FAILED_TRANSACTION: 'LOADING_FAILED_TRANSACTION',
  LOADED_FAILED_TRANSACTION: 'LOADED_FAILED_TRANSACTION',
  LOAD_FAILED_TRANSACTION_FAILED: 'LOAD_FAILED_TRANSACTION_FAILED',
  SET_CURRENT_TRANSACTION: 'SET_CURRENT_TRANSACTION',
  //POSTING TRANSACTION REMARKS
  POST_REMARKS: 'POST_REMARKS',
  REMARKS_POSTING: 'REMARKS_POSTING',
  REMARKS_POSTED: 'REMARKS_POSTED',
  REMARKS_POST_FAILED: 'REMARKS_POST_FAILED',
  // menu clicking actions
  SET_TITLE: 'SET_TITLE',
  CLEAR_TRANSACTION: 'CLEAR_TRANSACTION',
  CLEAR_TRANSACTION_ONLY: 'CLEAR_TRANSACTION_ONLY',
  // MQTT INCOMING TRANSACTION
  MQTT_INCOMMING_TRX: 'MQTT_INCOMMING_TRX',
  MQTT_INCOMMING_LOADING: 'MQTT_INCOMMING_LOADING',
  MQTT_INCOMMING_FAILED: 'MQTT_INCOMMING_FAILED',
  // SETVIEW
  SET_VIEW: 'SET_VIEW',
  MQTT_TRX: 'MQTT_TRX',
  // STORE INVENTORY
  LOAD_STORE_INVENTORY: 'LOAD_STORE_INVENTORY',
  LOADING_STORE_INVENTORY: 'LOADING_STORE_INVENTORY',
  STORE_INVENTORY_LOADED: 'STORE_INVENTORY_LOADED',
  LOAD_STORE_INVENTORY_FAILED: 'LOAD_STORE_INVENTORY_FAILED',
  RESET_STORE_INVENTORY: 'RESET_STORE_INVENTORY',
  COUNT_STORE_INVENTORY: 'COUNT_STORE_INVENTORY',
  COUNTING_STORE_INVENTORY: 'COUNTING_STORE_INVENTORY',
  COUNT_STORE_INVENTORY_RESULT: 'COUNT_STORE_INVENTORY_RESULT',
  // STORE PCHANNELS
  LOAD_ALLOWED_PCHANNEL: 'LOAD_ALLOWED_PCHANNEL',
  LOADING_ALLOWED_PCHANNEL: 'LOADING_ALLOWED_PCHANNEL',
  ALLOWED_PCHANNEL_LOADED: 'ALLOWED_PCHANNEL_LOADED',
  LOAD_ALLOWED_PCHANNEL_FAILED: 'LOAD_ALLOWED_PCHANNEL_FAILED',
  RESET_ALLOWED_PCHANNEL: 'RESET_ALLOWED_PCHANNEL',
  // ADDRESS
  // USED FOR PRIMARY LOADING OF PROVINCE
  LOAD_PROVINCE: 'LOAD_PROVINCE',
  LOADING_PROVINCE: 'LOADING_PROVINCE',
  PROVINCE_LOADED: 'PROVINCE_LOADED',
  LOAD_PROVINCE_FAILED: 'LOAD_PROVINCE_FAILED',
  RESET_PROVINCE: 'RESET_PROVINCE',
  // USED FOR SECONDARY SELECTING OF PROVINCE
  LOAD_PROVINCE_S: 'LOAD_PROVINCE_S',
  LOADING_PROVINCE_S: 'LOADING_PROVINCE_S',
  PROVINCE_LOADED_S: 'PROVINCE_LOADED_S',
  LOAD_PROVINCE_FAILED_S: 'LOAD_PROVINCE_FAILED_S',
  RESET_PROVINCE_S: 'RESET_PROVINCE_S',
  // USED FOR PRIMARY LOADING OF CITY
  LOAD_CITY: 'LOAD_CITY',
  LOADING_CITY: 'LOADING_CITY',
  CITY_LOADED: 'CITY_LOADED',
  LOAD_CITY_FAILED: 'LOAD_CITY_FAILED',
  RESET_CITY: 'RESET_CITY',
  // USED FOR SECONDARY SELECTING OF CITY
  LOAD_CITY_S: 'LOAD_CITY_S',
  LOADING_CITY_S: 'LOADING_CITY_S',
  CITY_LOADED_S: 'CITY_LOADED_S',
  LOAD_CITY_FAILED_S: 'LOAD_CITY_FAILED_S',
  RESET_CITY_S: 'RESET_CITY_S',
  // USED FOR PRIMARY LOADING OF BRGY 
  LOAD_BRGY: 'LOAD_BRGY',
  LOADING_BRGY: 'LOADING_BRGY',
  BRGY_LOADED: 'BRGY_LOADED',
  LOAD_BRGY_FAILED: 'LOAD_BRGY_FAILED',
  RESET_BRGY: 'RESET_BRGY',
  // USED FOR SECONDARY SELECTING OF BRGY
  LOAD_BRGY_S: 'LOAD_BRGY_S',
  LOADING_BRGY_S: 'LOADING_BRGY_S',
  BRGY_LOADED_S: 'BRGY_LOADED_S',
  LOAD_BRGY_FAILED_S: 'LOAD_BRGY_FAILED_S',
  RESET_BRGY_S: 'RESET_BRGY_S',
  // 
  LOAD_TEMP_TRANSACTION: 'LOAD_TEMP_TRANSACTION',
  LOADING_TEMP_TRANSACTION: 'LOADING_TEMP_TRANSACTION',
  TEMP_TRANSACTION_LOADED: 'TEMP_TRANSACTION_LOADED',
  LOAD_TEMP_TRANSACTION_FAILED: 'LOAD_TEMP_TRANSACTION_FAILED',
  RESET_TEMP_TRANSACTION: 'RESET_TEMP_TRANSACTION',

  SUBMIT_TEMP_TRX: 'SUBMIT_TEMP_TRX',
  SUBMITTING_TEMP_TRX: 'SUBMITTING_TEMP_TRX',
  TEMP_TRX_SUBMITTED: 'TEMP_TRX_SUBMITTED',
  // URL: 'URL',
  SUBMIT_TEMP_TRX_FAILED: 'SUBMIT_TEMP_TRX_FAILED',
  SUBMIT_TEMP_TRX_RESET: 'SUBMIT_TEMP_TRX_RESET',


  SUBMIT_TRX_REDIRECT_CUSTOMER: 'SUBMIT_TRX_REDIRECT_CUSTOMER',
  SUBMITTING_REDIRECT_CUSTOMER_TRX: 'SUBMITTING_REDIRECT_CUSTOMER_TRX',
  REDIRECT_CUSTOMER_TRX_SUBMITTED: 'REDIRECT_CUSTOMER_TRX_SUBMITTED',
  REDIRECT_CUSTOMER_PCHANNEL_DATA: 'REDIRECT_CUSTOMER_PCHANNEL_DATA',
  UPDATED_TRX_TEMP: 'UPDATED_TRX_TEMP',

  // SUBMIT PRE REGISTERED APPLICATION
  SUBMIT_APPLICATION: 'SUBMIT_APPLICATION',
  SUBMITTING_APPLICATION: 'SUBMITTING_APPLICATION',
  APPLICATION_SUBMITTED: 'APPLICATION_SUBMITTED',
  SUBMITTING_APPLICATION_FAILED: 'SUBMITTING_APPLICATION_FAILED',
  SUBMITTING_APPLICATION_ERROR: 'SUBMITTING_APPLICATION_ERROR',
  RESET_APPLICATION: 'RESET_APPLICATION',
  // SET BIZ MODULE
  SET_BIZ_MODULE: 'SET_BIZ_MODULE',

  // GET LIMIT
  GET_THRESHOLD: 'GET_THRESHOLD',
  GETTING_THRESHOLD: 'GETTING_THRESHOLD',
  GETTING_THRESHOLD_FAILED: 'GETTING_THRESHOLD_FAILED',
  GOT_THRESHOLD: 'GOT_THRESHOLD',

  //TEMP_ERROR_MESSAGE
  TEMP_ERROR_MESSAGE: 'TEMP_ERROR_MESSAGE',

  // SUBMIT TEMP TRANSACTION
  SUBMIT_TEMP_TRANSACTION: 'SUBMIT_TEMP_TRANSACTION',
  SUBMITTING_TEMP_TRANSACTION: 'SUBMITTING_TEMP_TRANSACTION',
  SUBMITTED_TEMP_TRANSACTION: 'SUBMITTED_TEMP_TRANSACTION',
  SUBMIT_TEMP_TRANSACTION_FAILED: 'SUBMIT_TEMP_TRANSACTION_FAILED',
  RESET_TEMP_TRANSACTION_MESSAGE: 'RESET_TEMP_TRANSACTION_MESSAGE',

  //GET TRX STATUS
  FETCH_TRX_STATUS: 'FETCH_TRX_STATUS',
  FETCHING_TRX_STATUS: 'FETCHING_TRX_STATUS',
  FETCHED_TRX_STATUS: 'FETCHED_TRX_STATUS',
  FETCH_TRX_STATUS_FAILED: 'FETCH_TRX_STATUS_FAILED',

  //SEARCH AUDITLOGS
  SEARCH_AUDITLOGS: 'SEARCH_AUDITLOGS',
  SEARCHING_AUDITLOGS: 'SEARCHING_AUDITLOGS',
  SEARCHED_AUDITLOGS: 'SEARCHED_AUDITLOGS',
  SEARCH_AUDITLOGS_FAILED: 'SEARCH_AUDITLOGS_FAILED',
  RESET_AUDITLOGS: 'RESET_AUDITLOGS',

  //LOAD MORE LOGS
  LOAD_MORE_LOGS: 'LOAD_MORE_LOGS',
  LOADING_MORE_LOGS: 'LOADING_MORE_LOGS',
  LOADED_MORE_LOGS: 'LOADED_MORE_LOGS',
  LOAD_MORE_LOGS_FAILED: 'LOAD_MORE_LOGS_FAILED',
  // DOWNLOAD TRANSACTION PDF
  DOWNLOAD_TRANSACTION_PDF: 'DOWNLOAD_TRANSACTION_PDF',
  DOWNLOADING_TRANSACTION_PDF: 'DOWNLOADING_TRANSACTION_PDF',
  DOWNLOADED_TRANSACTION_PDF: 'DOWNLOADED_TRANSACTION_PDF',
  DOWNLOAD_TRANSACTION_PDF_FAILED: 'DOWNLOAD_TRANSACTION_PDF_FAILED',
  RESET_DOWNLOAD_TRANSACTION_PDF: 'RESET_DOWNLOAD_TRANSACTION_PDF',

  loadFolders: (payload) => ({
    type: actions.LOAD_FOLDERS,
    payload: payload,
  }),
  setCurrentTransaction: (payload) => ({
    type: actions.SET_CURRENT_TRANSACTION,
    payload: payload,
  }),
  setEditFolderData: (payload) => ({
    type: actions.SET_EDIT_FOLDER_DATA,
    payload: payload,
  }),
  setEditTagData: (payload) => ({
    type: actions.SET_EDIT_TAG_DATA,
    payload: payload,
  }),
  toggleFilterPanel: () => ({
    type: actions.TOGGLE_FILTER_PANEL,
  }),
  toggleTagDialog: () => ({
    type: actions.TOGGLE_TAG_DIALOG,
  }),
  toggleFolderDialog: () => ({
    type: actions.TOGGLE_FOLDER_DIALOG,
  }),
  toggleEditFolderDialog: (payload) => ({
    type: actions.TOGGLE_EDIT_FOLDER_DIALOG,
    payload: payload,
  }),
  toggleEditTagDialog: (payload) => ({
    type: actions.TOGGLE_EDIT_TAG_DIALOG,
    payload: payload,
  }),
  toggleStoreListDialog: () => ({
    type: actions.TOGGLE_STORE_LIST_DIALOG,
  }),
  loadTags: (payload) => ({
    type: actions.LOAD_TAGS_TRANSACTION,
    payload: payload,
  }),
  fetchTags: () => ({
    type: actions.LOAD_TAGS,
  }),
  saveTag: (payload) => ({
    type: actions.SAVE_TAG,
    payload: payload,
  }),
  showSaveTagMessage: (payload) => ({
    type: actions.SHOW_SAVED_TAG_MESSAGE,
    payload: payload,
  }),
  saveTagMessage: (payload) => ({
    type: actions.SAVED_TAG_MESSAGE,
    payload: payload,
  }),
  showMenuEventMessage: (payload) => ({
    type: actions.SHOW_MENU_EVENT_MESSAGE,
    payload: payload,
  }),
  menuEventMessage: (payload) => ({
    type: actions.MENU_EVENT_MESSAGE,
    payload: payload,
  }),
  menuEventTriggering: (payload) => ({
    type: actions.MENU_EVENT_TRIGGERING,
    payload: payload,
  }),
  moveTag: (payload) => ({
    type: actions.MOVE_TAG,
    payload: payload,
  }),
  updateTag: (payload) => ({
    type: actions.UPDATE_TAG,
    payload: payload,
  }),
  deleteTag: (payload) => ({
    type: actions.DELETE_TAG,
    payload: payload,
  }),
  moveFolder: (payload) => ({
    type: actions.MOVE_FOLDER,
    payload: payload,
  }),
  saveFolder: (payload) => ({
    type: actions.SAVE_FOLDER,
    payload: payload,
  }),
  updateFolder: (payload) => ({
    type: actions.UPDATE_FOLDER,
    payload: payload,
  }),
  fetchFolders: () => ({
    type: actions.LOAD_FOLDERS,
  }),
  deleteFolder: (payload) => ({
    type: actions.DELETE_FOLDER,
    payload: payload,
  }),
  search: (payload) => ({
    type: actions.SEARCH,
    payload: payload,
  }),
  clearMessage: () => ({
    type: actions.CLEAR_MESSAGE,
  }),
  setTitle: (payload) => ({
    type: actions.SET_TITLE,
    payload: payload,
  }),
  fetchEsTrxByStore: (payload) => ({
    type: actions.LOAD_ES_TRX_BY_STORE,
    payload: payload,
  }),
  fetchMoreEsTrx: (payload) => ({
    type: actions.LOAD_MORE_ES_TRX,
    payload: payload,
  }),
  fetchEsTrxByTypeId: (payload) => ({
    type: actions.LOAD_ES_BY_TYPE_ID,
    payload: payload,
  }),
  loadTransactions: (payload) => ({
    type: actions.LOAD_ALL_TRANSACTION,
    payload: payload,
  }),
  loadMoreTransactions: (payload) => ({
    type: actions.LOAD_MORE_TRANSACTIONS,
    payload: payload,
  }),
  resetTransaction: () => ({
    type: actions.CLEAR_TRANSACTION,
  }),
  resetTransactionOnly: () => ({
    type: actions.CLEAR_TRANSACTION_ONLY,
  }),
  postRemarks: (payload) => ({
    type: actions.POST_REMARKS,
    payload: payload,
  }),
  issueRefund: (payload) => ({
    type: actions.ISSUE_REFUND,
    payload: payload,
  }),
  refundMessageReset: () => ({
    type: actions.REFUND_MESSAGE,
    payload: null,
    refundCode: null,
  }),
  newTransaction: (payload) => ({
    type: actions.MQTT_INCOMMING_TRX,
    payload,
  }),
  setView: (payload) => ({
    type: actions.SET_VIEW,
    payload,
  }),
  messageArrived: (payload) => ({
    type: actions.MQTT_TRX,
    payload: payload,
  }),
  loadStoreAssets: (payload) => ({
    type: actions.LOAD_STORE_INVENTORY,
    payload: payload,
  }),
  loadInventory: (payload) => ({
    type: actions.LOAD_STORE_INVENTORY,
    payload: payload,
  }),
  resetInventory: () => ({
    type: actions.RESET_STORE_INVENTORY,
  }),
  deleteFolderTxn: (payload, folderId) => ({
    type: actions.DELETE_FOLDER_TRANSACTION,
    payload: payload,
    folderId,
  }),
  deleteTransactionMessageReset: () => ({
    type: actions.FOLDER_TRANSACTION_DELETED,
    payload: null,
  }),
  loadProvince: () => ({
    type: actions.LOAD_PROVINCE,
  }),
  loadProvinceShipping: () => ({
    type: actions.LOAD_PROVINCE_S,
  }),
  loadCity: (payload) => ({
    type: actions.LOAD_CITY,
    payload: payload,
  }),
  loadCityShipping: (payload) => ({
    type: actions.LOAD_CITY_S,
    payload: payload,
  }),
  loadBrgy: (payload) => ({
    type: actions.LOAD_BRGY,
    payload: payload,
  }),
  loadBrgyShipping: (payload) => ({
    type: actions.LOAD_BRGY_S,
    payload: payload,
  }),
  loadTempTransaction: (payload) => ({
    type: actions.LOAD_TEMP_TRANSACTION,
    payload: payload,
  }),
  loadAllowedPchannels: (payload) => ({
    type: actions.LOAD_ALLOWED_PCHANNEL,
    payload: payload,
  }),
  resetAllowedPchannels: () => ({
    type: actions.RESET_ALLOWED_PCHANNEL,
  }),
  resetProvice: () => ({
    type: actions.RESET_PROVINCE,
  }),
  resetCity: () => ({
    type: actions.RESET_CITY,
  }),
  resetBrgy: () => ({
    type: actions.RESET_BRGY,
  }),
  submitTempTrx: (payload) => ({
    type: actions.SUBMIT_TEMP_TRX,
    payload: payload,
  }),
  redirectCustomerSubmitTrx: (payload) => ({
    type: actions.SUBMIT_TRX_REDIRECT_CUSTOMER,
    payload: payload,
  }),
  custRedirectPchannelData: (payload) => ({
    type: actions.REDIRECT_CUSTOMER_PCHANNEL_DATA,
    payload: payload,
  }),
  submitTempTrxReset: () => ({
    type: actions.SUBMIT_TEMP_TRX_RESET,
  }),
  tagFolderMessageReset: () => ({
    type: actions.TAG_FOLDER_MESSAGE,
    payload: null,
  }),
  removeTransactionTag: (payload, tag_id) => ({
    type: actions.REMOVE_TXN_TAG,
    payload: payload,
    tag_id: tag_id,
  }),
  submitApplication: (payload) => ({
    type: actions.SUBMIT_APPLICATION,
    payload: payload,
  }),
  resetApplication: () => ({
    type: actions.RESET_APPLICATION,
  }),
  setBizModule: (payload) => ({
    type: actions.SET_BIZ_MODULE,
    payload,
  }),
  getLimit: () => ({
    type: actions.GET_THRESHOLD,
  }),
  countStoreInventory: (payload) => ({
    type: actions.COUNT_STORE_INVENTORY,
    payload,
  }),
  resetTempErrorMessage: () => ({
    type: actions.TEMP_ERROR_MESSAGE,
    payload: null,
  }),
  submitTempTransaction: (payload) => ({
    type: actions.SUBMIT_TEMP_TRANSACTION,
    payload,
  }),
  resetTempTransactionMessage: () => ({
    type: actions.RESET_TEMP_TRANSACTION_MESSAGE,
  }),
  fetchTrxStatus: (payload) => ({
    type: actions.FETCH_TRX_STATUS,
    payload: payload,
  }),
  searchAuditLogs: (payload) => ({
    type: actions.SEARCH_AUDITLOGS,
    payload: payload,
  }),
  loadMoreLogs: (payload) => ({
    type: actions.LOAD_MORE_LOGS,
    payload: payload,
  }),
  downloadTrxPdf: (payload) => ({
    type: actions.DOWNLOAD_TRANSACTION_PDF,
    payload: payload,
  }),
  resetDownloadTrxPdf: () => ({
    type: actions.RESET_DOWNLOAD_TRANSACTION_PDF,
  }),
  resetAuditLogs: () => ({
    type: actions.RESET_AUDITLOGS,
  }),
};
export default actions; 