const actions = {
  // generic actions
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  // load inventory events
  LOAD_INVENTORY: 'LOAD_INVENTORY',
  LOAD_ALL_INVENTORY: 'LOAD_ALL_INVENTORY',
  LOADING_INVENTORY: 'LOADING_INVENTORY',
  LOAD_MORE_INVENTORY: 'LOAD_MORE_INVENTORY',
  LOADING_MORE_INVENTORY: 'LOADING_MORE_INVENTORY',
  INVENTORY_LOADED: 'INVENTORY_LOADED',
  SEARCH_INVENTORY_LOADED: 'SEARCH_INVENTORY_LOADED',
  LOAD_INVENTORY_FAILED: 'LOAD_INVENTORY_FAILED',
  // menu clicking actions
  SET_INV_TITLE: 'SET_INV_TITLE',
  CLEAR_INVENTORY: 'CLEAR_INVENTORY',
  CLEAR_INVENTORY_ONLY: 'CLEAR_INVENTORY_ONLY',
  // SETVIEW
  SET_VIEW: 'SET_VIEW',
  // STORE INVENTORY
  LOAD_STORE_INVENTORY: 'LOAD_STORE_INVENTORY',
  LOADING_STORE_INVENTORY: 'LOADING_STORE_INVENTORY',
  STORE_INVENTORY_LOADED: 'STORE_INVENTORY_LOADED',
  LOAD_STORE_INVENTORY_FAILED: 'LOAD_STORE_INVENTORY_FAILED',
  RESET_STORE_INVENTORY: 'RESET_STORE_INVENTORY',
  SET_CURRENT_INVENTORY_ITEM: 'SET_CURRENT_INVENTORY_ITEM',
  // STORE PCHANNELS
  LOAD_ALLOWED_PCHANNEL: 'LOAD_ALLOWED_PCHANNEL',
  LOADING_ALLOWED_PCHANNEL: 'LOADING_ALLOWED_PCHANNEL',
  ALLOWED_PCHANNEL_LOADED: 'ALLOWED_PCHANNEL_LOADED',
  LOAD_ALLOWED_PCHANNEL_FAILED: 'LOAD_ALLOWED_PCHANNEL_FAILED',
  RESET_ALLOWED_PCHANNEL: 'RESET_ALLOWED_PCHANNEL',
  // INVENTORY LOGS
  LOAD_INVENTORY_LOGS: 'LOAD_INVENTORY_LOGS',
  LOADING_INVENTORY_LOGS: 'LOADING_INVENTORY_LOGS',
  INVENTORY_LOGS_LOADED: 'INVENTORY_LOGS_LOADED',
  LOAD_INVENTORY_LOGS_FAILED: 'LOAD_INVENTORY_LOGS_FAILED',
  RESET_INVENTORY_LOGS: 'RESET_INVENTORY_LOGS',
  // ADD DIALOG
  TOGGLE_ADD_DIALOG: 'TOGGLE_ADD_DIALOG',
  ADD_ITEM: 'ADD_ITEM',
  ITEM_SAVING: 'ITEM_SAVING',
  ITEM_ADDED: 'ITEM_ADDED',
  ITEM_ADD_FAILED: 'ITEM_ADD_FAILED',
  RESET_ITEM_SAVED: 'RESET_ITEM_SAVED',
  // REPLENISH DIALOG
  REPLENISH_ITEM: 'REPLENISH_ITEM',
  ITEM_REPLENISHING: 'ITEM_REPLENISHING',
  ITEM_REPLENISHED: 'ITEM_REPLENISHED',
  ITEM_REPLENISH_FAILED: 'ITEM_REPLENISH_FAILED',
  RESET_ITEM_REPLENISHED: 'RESET_ITEM_REPLENISHED',
  // EDIT DETAILS DIALOG
  EDIT_ITEM: 'EDIT_ITEM',
  ITEM_EDITING: 'ITEM_EDITING',
  ITEM_EDITED: 'ITEM_EDITED',
  ITEM_EDIT_FAILED: 'ITEM_EDIT_FAILED',
  RESET_ITEM_EDITED: 'RESET_ITEM_EDITED',
  // ADD EVENT
  SHOW_ADD_EVENT_MESSAGE: 'SHOW_ADD_EVENT_MESSAGE',
  ADD_EVENT_MESSAGE: 'ADD_EVENT_MESSAGE',
  ADD_EVENT_TRIGGERING: 'ADD_EVENT_TRIGGERING',
  // DELETE ITEM
  DELETE_ITEM: 'DELETE_ITEM',
  ITEM_DELETING: 'ITEM_DELETING',
  ITEM_DELETED: 'ITEM_DELETED',
  ITEM_DELETING_FAILED: 'ITEM_DELETING_FAILED',
  RESET_ITEM_DELETED: 'RESET_ITEM_DELETED',
  //GET QR IMAGE
  GET_QR_IMAGE: 'GET_QR_IMAGE',
  GETTING_QR_IMAGE: 'GETTING_QR_IMAGE',
  GET_QR_IMAGE_FAILED: 'GET_QR_IMAGE_FAILED',
  QR_IMAGE: 'QR_IMAGE',
  // DOWNLOAD QR
  DOWNLOAD_QR_PDF: 'DOWNLOAD_QR_PDF',
  DOWNLOADING_QR_PDF: 'DOWNLOADING_QR_PDF',
  DOWNLOADED_QR_PDF: 'DOWNLOADED_QR_PDF',
  DOWNLOAD_QR_PDF_FAILED: 'DOWNLOAD_QR_PDF_FAILED',
  RESET_DOWNLOADED_QR_PDF: 'RESET_DOWNLOADED_QR_PDF',
  //FETCH PRODUCT BY ID
  FETCH_PRODUCT_BY_ID: 'FETCH_PRODUCT_BY_ID',
  FETCHING_PRODUCT_BY_ID: 'FETCHING_PRODUCT_BY_ID',
  FETCHED_PRODUCT_BY_ID: 'FETCHED_PRODUCT_BY_ID',
  FETCH_PRODUCT_BY_ID_FAILED: 'FETCH_PRODUCT_BY_ID_FAILED',
  setCurrentInventory: (payload) => ({
    type: actions.SET_CURRENT_INVENTORY_ITEM,
    payload: payload,
  }),
  clearMessage: () => ({
    type: actions.CLEAR_MESSAGE,
  }),
  setTitle: (payload) => ({
    type: actions.SET_INV_TITLE,
    payload: payload,
  }),
  loadInventory: (payload) => ({
    type: actions.LOAD_ALL_INVENTORY,
    payload: payload,
  }),
  loadMoreInventory: (payload) => ({
    type: actions.LOAD_MORE_INVENTORY,
    payload: payload,
  }),
  resetInventory: () => ({
    type: actions.CLEAR_INVENTORY,
  }),
  setView: (payload) => ({
    type: actions.SET_VIEW,
    payload,
  }),
  loadStoreInventory: (payload) => ({
    type: actions.LOAD_STORE_INVENTORY,
    payload: payload,
  }),
  resetStoreInventory: () => ({
    type: actions.RESET_STORE_INVENTORY,
  }),
  loadAllowedPchannels: (payload) => ({
    type: actions.LOAD_ALLOWED_PCHANNEL,
    payload: payload,
  }),
  resetAllowedPchannels: () => ({
    type: actions.RESET_ALLOWED_PCHANNEL,
  }),
  loadInventoryLogs: (payload) => ({
    type: actions.LOAD_INVENTORY_LOGS,
    payload: payload,
  }),
  resetInventoryLogs: () => ({
    type: actions.RESET_INVENTORY_LOGS,
  }),
  toggleAddDialog: () => ({
    type: actions.TOGGLE_ADD_DIALOG,
  }),
  addItem: (payload) => ({
    type: actions.ADD_ITEM,
    payload: payload,
  }),
  showAddEventMessage: (payload) => ({
    type: actions.SHOW_ADD_EVENT_MESSAGE,
    payload: payload,
  }),
  addEventMessage: (payload) => ({
    type: actions.ADD_EVENT_MESSAGE,
    payload: payload,
  }),
  addEventTriggering: (payload) => ({
    type: actions.ADD_EVENT_TRIGGERING,
    payload: payload,
  }),
  resetItemSaved: () => ({
    type: actions.RESET_ITEM_SAVED,
  }),
  replenishItem: (payload) => ({
    type: actions.REPLENISH_ITEM,
    payload: payload,
  }),
  resetItemReplenished: () => ({
    type: actions.RESET_ITEM_REPLENISHED,
  }),
  editItem: (payload) => ({
    type: actions.EDIT_ITEM,
    payload: payload,
  }),
  resetItemEdited: () => ({
    type: actions.RESET_ITEM_EDITED,
  }),
  deleteItem: (payload) => ({
    type: actions.DELETE_ITEM,
    payload: payload,
  }),
  resetItemDeleted: () => ({
    type: actions.RESET_ITEM_DELETED,
  }),
  getQrImage: (payload) => ({
    type: actions.GET_QR_IMAGE,
    payload,
  }),
  downloadQrPdf: (payload) => ({
    type: actions.DOWNLOAD_QR_PDF,
    payload,
  }),
  resetDownloadedQr: () => ({
    type: actions.RESET_DOWNLOADED_QR_PDF,
  }),
  fetchProductById: (payload) => ({
    type: actions.FETCH_PRODUCT_BY_ID,
    payload,
  }),
};
export default actions; 