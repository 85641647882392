import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import transactionsSaga from '@iso/redux/transactions/saga';
import dashboardSaga from '@iso/redux/dashboard/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import mqttSaga from '@iso/redux/mqtt/saga';
import merchantSaga from '@iso/redux/merchant/saga';
import inventorySaga from '@iso/redux/inventory/saga';
import financialsSaga from '@iso/redux/financials/saga';
import storeSaga from '@iso/redux/store/saga';
import settingsSaga from '@iso/redux/settings/saga';


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    ecommerceSaga(),
    inventorySaga(),
    financialsSaga(),
    merchantSaga(),
    mqttSaga(),
    transactionsSaga(),
    dashboardSaga(),
    storeSaga(),
    settingsSaga(),
  ]);
}
