import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import { get, post, PUT } from '@iso/lib/helpers/resApiRequestor';
import * as _ from 'lodash';

export function* withdrawalRequest() {
  yield takeEvery('GET_WITHDRAWAL_REQUEST', function* () {
    // console.log('BEFORE_REQUEST');
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    try {
      yield put({
        type: actions.GETTING_WITHDRAWAL_REQUEST,
        payload: true,
      });
      // const apiResultD = yield withdrawalRequesting();
      const apiResultD = yield withdrawalRequestingNew(merchantId);
      const result = apiResultD.data || null;
      // console.log('RESULT_WITHDRAWAL_REQUEST', result)
      if (result.success) {
        yield put({
          type: actions.GETTING_WITHDRAWAL_REQUEST,
          payload: false,
        });
        yield put({
          type: actions.GET_WITHDRAWAL_REQUEST_SUCCESS,
          payload: result.data.result,
        });
      } else {
        yield put({
          type: actions.GET_WITHDRAWAL_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_WITHDRAWAL_FAILED,
      });
    }
  });
}
export function* loadBalance() {
  yield takeEvery('LOAD_WALLET_BALANCE', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    const loadingBalances = state.Financials.loadingBalances;
    // console.log('LOAD_WALLET_BALANCE', loadingBalances)
    if (!loadingBalances) {
      try {
        yield put({
          type: actions.LOADING_BALANCES,
        });
        yield put({
          type: actions.LOADING_LIMIT,
        });
        yield put({
          type: actions.LOADING_BANK_ACCOUNTS,
        });
        const apiResult = yield fetchBalance(merchantId);
        const result = apiResult.data;
        if (result.success) {
          const apiResultB = yield fetchWithdrawableBalance(merchantId);
          console.log('LOAD_WALLET_BALANCE B', apiResultB)
          const resultB = apiResultB.data.withdrawalBalance || '0.00';
          if (apiResultB.data.success) {
            const walletDetails = {
              ...result.data,
              withdrawable: resultB,
            }
            yield put({
              type: actions.BALANCES_LOADED,
              walletDetails: walletDetails,
            });

            const apiResultC = yield getLimit(merchantId);
            // console.log('LOAD_WALLET_BALANCE C', apiResultC)
            const resultc = apiResultC.data || null;
            if (apiResultC.status === 200) {
              yield put({
                type: actions.LIMIT_LOADED,
                limitDetails: resultc,
              });
              const apiResultD = yield fetchBankAccount(merchantId);
              // console.log('LOAD_WALLET_BALANCE D', apiResultD)
              const resultd = apiResultD.data || null;
              // console.log(resultd)
              if (resultd.success) {
                yield put({
                  type: actions.BANK_ACCOUNTS_LOADED,
                  bankAccounts: resultd.data,
                });
              } else {
                yield put({
                  type: actions.LOADING_BANK_ACCOUNTS_FAILED,
                });
              }

            } else {
              yield put({
                type: actions.LOADING_LIMIT_FAILED,
              });
            }
          } else {
            yield put({
              type: actions.LOADING_BALANCES_FAILED,
            });
          }

        }
      } catch (error) {
        yield put({
          type: actions.LOADING_BALANCES_FAILED,
        });
      }
    }
  });
}
export function* loadBankAccounts() {
  yield takeEvery('LOAD_BANK_ACCOUNTS', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    const loadingBalances = state.Financials.loadingBalances;
    if (!loadingBalances) {
      try {
        yield put({
          type: actions.LOADING_BANK_ACCOUNTS,
        });
        const apiResultD = yield fetchBankAccount(merchantId);
        // console.log('LOAD_WALLET_BALANCE D', apiResultD)
        const resultd = apiResultD.data || null;
        console.log('CHECK BANKS', resultd)
        if (resultd.success) {
          yield put({
            type: actions.BANK_ACCOUNTS_LOADED,
            bankAccounts: resultd.data,
          });
        } else {
          yield put({
            type: actions.LOADING_BANK_ACCOUNTS_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.LOADING_BANK_ACCOUNTS_FAILED,
        });
      }
    }
  });
}
export function* requestOtp() {
  yield takeEvery('REQUEST_OTP', function* ({ payload }) {
    // console.log('BEFORE_REQUEST');
    try {
      yield put({
        type: actions.REQUESTING_OTP,
        payload: true,
      });
      const apiResultD = yield requestingOtp(payload);
      const result = apiResultD.data || null;
      // console.log('RESULT_OTP', result)
      if (result.success) {
        yield put({
          type: actions.REQUESTING_OTP,
          payload: false,
        });
      } else {
        yield put({
          type: actions.REQUEST_OTP_FAILED,
        });
      }
      yield put({
        type: actions.REQUESTED_OTP,
        payload: result.success ? result.data : result.message ? result.message : 'Error in sending otp!',
        isErrorOtp: result.success ? false : true,
      });
    } catch (error) {
      yield put({
        type: actions.REQUEST_OTP_FAILED,
      });
    }
  });
}
export function* verifyOtp() {
  yield takeEvery('VERIFY_OTP', function* ({ signature, payload, accountType }) {
    // console.log('SIGNATURE', signature);
    // console.log('PAYLOAD', payload);
    try {
      yield put({
        type: actions.VERIFYING_OTP,
        payload: true,
      });
      let apiResult;
      if (accountType === 'Corporate') {
        apiResult = yield verifyingOtpCorporate(payload);
      } else {
        apiResult = yield verifyingOtp(signature, payload);
      }
      const result = apiResult.data || null;
      console.log('RESULT_VERIFY', result)
      if (result.status_text) {
        yield put({
          type: actions.VERIFIED_OTP,
          payload: 'Your withdraw is currently proccessing!',
          isErrorVerifyOtp: false,
        });
      } else {
        yield put({
          type: actions.VERIFY_OTP_FAILED,
        });
        if (result.code) {
          yield put({
            type: actions.VERIFIED_OTP,
            payload: result.advise,
            isErrorVerifyOtp: true,
          });
        } else if (result.response_code) {
          yield put({
            type: actions.VERIFIED_OTP,
            payload: result.response_message,
            isErrorVerifyOtp: true,
          });
        }
      }
      yield put({
        type: actions.VERIFYING_OTP,
        payload: false,
      });
    } catch (error) {
      console.log('ERROR2', error);
      yield put({
        type: actions.VERIFY_OTP_FAILED,
      });
    }
  });
}
export function* loadSupportedBanks() {
  yield takeEvery('LOAD_SUPPORTED_BANKS', function* ({ payload }) {
    const state = yield select();
    const loadBankAccounts = state.Financials.loadBankAccounts;
    if (!loadBankAccounts) {
      try {
        yield put({
          type: actions.LOADING_SUPPORTED_BANKS,
        });
        const apiResult = yield getSupportedBanks();
        // console.log('CHECK SUPPORTED BANKS', apiResult);
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.SUPPORTED_BANK_LOADED,
            supportedBanks: result.data,
          });
        } else {
          yield put({
            type: actions.LOADING_SUPPORTED_BANKS_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.LOADING_SUPPORTED_BANKS_FAILED,
        });
      }
    }
  });
}
export function* sendSmsOtp() {
  yield takeEvery('SEND_SMS_OTP', function* ({ payload }) {
    // console.log('CHECK SMS DATA 1', payload);
    if (payload) {
      try {
        // console.log('CHECK SMS DATA 2', payload);
        yield put({
          type: actions.SENDING_SMS_OTP,
        });
        const apiResult = yield sendOtpSms(payload);
        // console.log('CHECK SMS RESULT', apiResult);
        const result = apiResult.data || null;
        if (result === "Success") {
          yield put({
            type: actions.SMS_OTP_SENT,
          });
        } else {
          yield put({
            type: actions.SENDING_SMS_OTP_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.SENDING_SMS_OTP_FAILED,
        });
      }
    }
  });
}
export function* saveBankAccount() {
  yield takeEvery('SAVE_BANK_ACCOUNT', function* ({ payload }) {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.SAVING_BANK_ACCOUNT,
        });
        const apiResult = yield insertBankAccount(payload);
        const result = apiResult.data || null;
        if (result.success) {
          const apiResultB = yield fetchBankAccount(merchantId);
          const bankAccounts = apiResultB.data || null;
          if (bankAccounts.success) {
            yield put({
              type: actions.BANK_ACCOUNT_SAVED,
              result: result.data,
              bankAccounts: bankAccounts.data,
            });
          } else {
            yield put({
              type: actions.BANK_ACCOUNT_SAVED,
              result: result.data,
            });
          }
        } else {
          yield put({
            type: actions.SAVING_BANK_ACCOUNT_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.SAVING_BANK_ACCOUNT_FAILED,
        });
      }
    }
  });
}
export function* resendOtp() {
  yield takeEvery('RESEND_OTP', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.RESENDING_OTP,
        });
        const apiResult = yield resendOtpSms(payload);
        // console.log('CHECK RESEND RESULT', apiResult);
        const result = apiResult.data || null;
        if (result === "Success") {
          yield put({
            type: actions.RESEND_OTP_SENT,
            message: 'OTP successfully resend!',
          });
        } else if (result === 'FAILED NO MOBILE FOUND') {
          yield put({
            type: actions.RESENDING_OTP_FAILED,
            message: 'NO OTP RECIPIENT',
          });
        } else {
          yield put({
            type: actions.RESENDING_OTP_FAILED,
            message: 'OTP failed to resend!',
          });
        }
      } catch (error) {
        yield put({
          type: actions.RESENDING_OTP_FAILED,
          message: 'OTP failed to resend!',
        });
      }
    }
  });
}
export function* wResendOtp() {
  yield takeEvery('W_RESEND_OTP', function* ({ payload }) {
    try {
      yield put({
        type: actions.W_RESENDING_OTP,
        payload: true,
      });
      const apiResultD = yield resendingOtp(payload);
      const result = apiResultD.data || null;
      // console.log('RESULT_RESEND_OTP', result)
      if (result.success) {
        yield put({
          type: actions.W_RESEND_OTP_SUCCESS,
          payload: result.data,
          isErrorResendOtp: false,
        });
      } else {
        yield put({
          type: actions.W_RESEND_OTP_SUCCESS,
          payload: result.message ? result.message : 'Error in resending otp!',
          isErrorResendOtp: true,
        });
      }
      yield put({
        type: actions.W_RESENDING_OTP,
        payload: false,
      });
    } catch (error) {
      yield put({
        type: actions.W_RESEND_OTP_FAILED,
      });
    }
  });
}
export function* updateBankAccount() {
  yield takeEvery('UPDATE_BANK_ACCOUNT', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.UPDATING_BANK_ACCOUNT,
        });
        const apiResult = yield editBankAccount(payload);
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.BANK_ACCOUNT_UPDATED,
            bankAccounts: result.data.bankAccountUpdated,
          });
        } else {
          yield put({
            type: actions.UPDATING_BANK_ACCOUNT_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.UPDATING_BANK_ACCOUNT_FAILED,
        });
      }
    }
  });
}
export function* removeBankAccount() {
  yield takeEvery('REMOVE_BANK_ACCOUNT', function* ({ payload }) {
    const state = yield select();
    const bankAccounts = state.Financials.bankAccounts;
    if (payload) {
      try {
        yield put({
          type: actions.REMOVING_BANK_ACCOUNT,
        });
        const apiResult = yield deleteBankAccount(payload);
        const result = apiResult.data || null;
        if (result.success) {
          const updatedAccounts = _.reject(bankAccounts, { Id: payload });
          yield put({
            type: actions.BANK_ACCOUNT_REMOVED,
            bankAccounts: updatedAccounts,
          });
        } else {
          yield put({
            type: actions.REMOVING_BANK_ACCOUNT_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.REMOVING_BANK_ACCOUNT_FAILED,
        });
      }
    }
  });
}
export function* changeFavorite() {
  yield takeEvery('CHANGE_FAVORITE', function* ({ payload }) {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.CHANGING_FAVORITE,
        });
        const apiResult = yield changeFavoriteValue(payload);
        const result = apiResult.data || null;
        if (result.success) {
          const apiResultB = yield fetchBankAccount(merchantId);
          const bankAccounts = apiResultB.data || null;
          if (bankAccounts.success) {
            yield put({
              type: actions.FAVORITE_CHANGED,
              bankAccounts: bankAccounts.data,
            });
          } else {
            yield put({
              type: actions.FAVORITE_CHANGED,
            });
          }
        } else {
          yield put({
            type: actions.CHANGING_FAVORITE_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.CHANGING_FAVORITE_FAILED,
        });
      }
    }
  });
}
export function* changeStatus() {
  yield takeEvery('CHANGE_STATUS', function* ({ payload }) {
    const state = yield select();
    const merchantId = state.Auth.selectedMerchant;
    if (payload) {
      try {
        yield put({
          type: actions.CHANGING_STATUS,
        });
        const apiResult = yield changeStatusValue(payload);
        // console.log('apiResult', apiResult);
        const result = apiResult.data || null;
        if (result.success) {
          const apiResultB = yield fetchBankAccount(merchantId);
          const bankAccounts = apiResultB.data || null;
          if (bankAccounts.success) {
            yield put({
              type: actions.STATUS_CHANGED,
              bankAccounts: bankAccounts.data,
            });
          } else {
            yield put({
              type: actions.STATUS_CHANGED,
            });
          }
        } else {
          yield put({
            type: actions.CHANGING_STATUS_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.CHANGING_STATUS_FAILED,
        });
      }
    }
  });
}
export function* downloadWithdrawal() {
  yield takeEvery('DOWNLOAD_WITHDRAWAL', function* ({ payload }) {
    // const merchantId = state.Auth.identity.merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.DOWNLOAD_WITHDRAWAL_LOADING,
          payload: true,
        });
        const apiResult = yield downloadWithdrawalReport(payload);
        const result = apiResult.data || null;
        console.log('RESULT_WITHDRAWAL', result);
        if (!result.success) {
          yield put({
            type: actions.DOWNLOAD_WITHDRAWAL_MESSAGE,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.DOWNLOAD_WITHDRAWAL_ERROR,
        });
      }
    }
  });
}

export function* fetchwithdrawalRequest() {
  yield takeEvery('FETCH_WITHDRAWAL_REQUEST', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.FETCHING_WITHDRAWAL_REQUEST,
          payload: true,
        });
        const apiResult = yield withdrawalRequestingByReqId(payload);
        const result = apiResult.data || null;
        console.log('RESULT_WITHDRAWAL', result);
        if (result.success) {
          yield put({
            type: actions.FETCH_WITHDRAWAL_REQUEST_SUCCESS,
            payload: result.data
          });
        } else {
          yield put({
            type: actions.FETCH_WITHDRAWAL_REQUEST_FAILED,
            payload: 0,
          });
        }
      } catch (error) {
        yield put({
          type: actions.FETCH_WITHDRAWAL_REQUEST_FAILED,
          payload: 0,
        });
      }
    }
  });
}
export function* updateWithdrawalStatus() {
  yield takeEvery('UPDATE_WITHDRAWAL_STATUS', function* ({ payload }) {
    const state = yield select();
    const withdrawalData = state.Financials.withdrawalData;
    if (payload) {
      try {
        yield put({
          type: actions.UPDATING_WITHDRAWAL_STATUS,
          payload: true,
        });
        const apiResult = yield updatingWithdrawalStatus(payload);
        const result = apiResult.data || null;
        console.log('RESULT_UPDATING_WITHDRAWAL_STATUS', result);
        if (result.success) {

          const foundIndex = withdrawalData.findIndex(x => x.requestId === payload.requestId);
          withdrawalData[foundIndex] = result.data;
          console.log('WITHDRAW_DATA',withdrawalData);
          // REPLACE OLD WITHDRAWAL DATA WITH NEW
          yield put({
            type: actions.GET_WITHDRAWAL_REQUEST_SUCCESS,
            payload: withdrawalData
          });
          yield put({
            type: actions.UPDATING_WITHDRAWAL_STATUS,
            payload: false
          });
          yield put({
            type: actions.UPDATED_WITHDRAWAL_STATUS,
            payload: result.data
          });
        }
      } catch (error) {
        yield put({
          type: actions.UPDATING_FAILED_WITHDRAWAL_STATUS,
          payload: false,
        });
      }
    }
  });
}

function resendingOtp(data) {
  return post(`withdrawsmsreqs/resend/otp-v2/`, data);
}
// function withdrawalRequesting() {
//   return get(`disbursementreqs/v-2/`);
// }
function withdrawalRequestingNew(mid) {
  return get(`disbursementreqs/v-2/${mid}`);
}
function withdrawalRequestingByReqId(id) {
  return get(`disbursementreqs/v-2/get_request_id/${id}`);
}
function verifyingOtp(signature, data) {
  return post(`disbursementreqs/withdraw/${signature}`, data);
}
function verifyingOtpCorporate(data) {
  return post(`disbursementreqs/withdraw_corporate`, data);
}
function fetchBalance(mid) {
  // console.log('STORE-ID', storeId);
  return get(`merchantwallets/get_by_merchant_id/${mid}`);
}
function requestingOtp(data) {
  // console.log('STORE-ID', storeId);
  return post(`withdrawsmsreqs/request_otp`, data);
}
function fetchWithdrawableBalance(mid) {
  // console.log('STORE-ID', storeId);
  return get(`withdraw/ui/balance/${mid}`);
}
function fetchBankAccount(mid) {
  // console.log('STORE-ID', storeId);
  return get(`merchantwithdrawalaccounts/fetch_by_mid/${mid}`);
}
function getLimit(mid) {
  // console.log('STORE-ID', storeId);
  return get(`payment_transactions/get_aggregated_report/${mid}`);
}
function getSupportedBanks() {
  return get(`supportedbanks/get_all_supported_banks`);
}
function sendOtpSms(payload) {
  return post(`withdrawsmsreqs/`, payload);
}
function insertBankAccount(payload) {
  return post(`merchantwithdrawalaccounts/`, payload);
}
function editBankAccount(payload) {
  return PUT(`merchantwithdrawalaccounts/update_bank_account/${payload.Id}`, payload);
}
function deleteBankAccount(id) {
  return PUT(`merchantwithdrawalaccounts/delete/${id}`);
}
function changeFavoriteValue(payload) {
  return PUT(`merchantwithdrawalaccounts/change_favorite/${payload.id}`, payload);
}
function changeStatusValue(payload) {
  return PUT(`merchantwithdrawalaccounts/change_status/${payload.id}`, payload);
}
function resendOtpSms(payload) {
  return post(`withdrawsmsreqs/resend/otp/`, payload);
}
function downloadWithdrawalReport(payload) {
  return post(`downloadtransactions/withdrawal_request_ui/`, payload);
}
function updatingWithdrawalStatus(payload) {
  console.log('PAYLOAD', payload);
  return post(`disbursementreqs/update_withdrawal_status/`, payload);
}
export default function* rootSaga() {
  yield all([
    fork(loadBalance),
    fork(loadBankAccounts),
    fork(requestOtp),
    fork(verifyOtp),
    fork(loadSupportedBanks),
    fork(sendSmsOtp),
    fork(saveBankAccount),
    fork(resendOtp),
    fork(wResendOtp),
    fork(updateBankAccount),
    fork(removeBankAccount),
    fork(changeFavorite),
    fork(withdrawalRequest),
    fork(downloadWithdrawal),
    fork(fetchwithdrawalRequest),
    fork(updateWithdrawalStatus),
    fork(changeStatus),
  ]);
}