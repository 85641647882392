import actions from './actions';
import storeActions from '../store/actions';

const initState = {
  idToken: null,
  errorMessage: null,
  signupMessage: null,
  forgotMessage: null,
  modules: null,
  customModules: null,
  roles: null,
  isLoading: false,
  resetSuccess: false,
  identity: null,
  stores: null,
  merchantId: null,
  topic: null,
  secondTimer: 0,
  env: null,
  mqttToken: null,
  forbiddenRequest: false,
  kickedOut: false,
  preRegistered: false,
  customAuthorizations: null,
  showKyc: false,
  disabledModules: ['CORPORATE_STORE_USER'],
  disabledSubmodules: [],
  merchantList: null,
  selectedMerchant: null,
  selectedMerchantName: null,
  fetchingRedirectUrl: false,
  redirectUrl: null,
  redirectRegistrationSuccessData: null,
  redirectSendingEmail: false,
  redirectSendingSuccess: false,
  redirectSendingFailed: false,
  redirectSendingMessage: null,
  fetchingResetPassUrl: false,
  resetPassUrl: null,
};

export default function authReducer(state = initState, action) {

  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      const newRegistered = JSON.parse(action.modules).filter((el) => {
        return el.alias === 'PRE_REG_GET_STARTED';
      }).length > 0;
      // const identity = JSON.parse(action.identity);
      // let show = false;
      // if (identity.userSettings) show = true;

      // sanitize module and remove disabled modules
      const mods = JSON.parse(action.modules).filter((el) => {
        const submods = el.submodules.filter((elB) => {
          return state.disabledSubmodules.indexOf(elB.alias) < 0;
        });
        el.submodules = submods;
        return state.disabledModules.indexOf(el.alias) < 0;
      });

      return {
        ...state,
        idToken: action.token,
        identity: JSON.parse(action.identity),
        modules: mods,
        stores: JSON.parse(action.stores),
        merchantId: action.merchantId,
        customAuthorizations: JSON.parse(action.customAuthorizations),
        customModules: JSON.parse(action.customModules),
        roles: JSON.parse(action.roles),
        errorMessage: null,
        topic: action.topic,
        env: action.env,
        preRegistered: newRegistered,
        merchantList: action.merchantList ? JSON.parse(action.merchantList) : [],
        selectedMerchant: action.selectedMerchant,
        selectedMerchantName: action.selectedMerchantName,
      };
    case actions.FULLY_REGISTERED:
      // console.log('FULLY_REGISTERED', action);
      // sanitize module and remove disabled modules
      const mods2 = JSON.parse(action.modules).filter((el) => {
        const submods = el.submodules.filter((elB) => {
          return state.disabledSubmodules.indexOf(elB.alias) < 0;
        });
        el.submodules = submods;
        return state.disabledModules.indexOf(el.alias) < 0;
      });
      return {
        ...state,
        identity: JSON.parse(action.identity),
        modules: mods2,
        stores: JSON.parse(action.stores),
        merchantId: action.merchantId,
        customAuthorizations: JSON.parse(action.customAuthorizations),
        customModules: JSON.parse(action.customModules),
        roles: JSON.parse(action.roles),
        errorMessage: null,
        topic: action.topic,
        preRegistered: false,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        kickedOut: false,
        errorMessage: action.error,
      };
    case actions.CLEAR_ERROR:
      return {
        ...state,
        errorMessage: null,
        signupMessage: null,
      };
    case actions.SET_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case actions.SIGNUP:
      return {
        ...state,
        isLoading: action.loading,
      };
    case actions.SIGNUP_ERROR:
      return {
        ...state,
        signupMessage: action.error,
      };
    case actions.FORGOT_ERROR:
      return {
        ...state,
        forgotMessage: action.error,
      };
    case actions.SUBMODULES:
      // sanitize module and remove disabled modules
      // console.log(action)
      const mods3 = action.payload.filter((el) => {
        const submods = el.submodules.filter((elB) => {
          return state.disabledSubmodules.indexOf(elB.alias) < 0;
        });
        el.submodules = submods;
        return state.disabledModules.indexOf(el.alias) < 0;
      });
      return {
        ...state,
        modules: mods3,
      };
    case actions.FORGOT_PASSWORD:
      return {
        ...state,
        email: action.email
      };
    case actions.FORGOT_PASSWORD_DONE:
      return {
        ...state,
        resetSuccess: action.success,
        forgotMessage: null,
      };
    case actions.RESET_SECOND_TIMER:
      return {
        ...state,
        secondTimer: action.secondTimer,
      };
    case actions.KICKED_OUT:
      return {
        ...state,
        kickedOut: true,
      };
    case actions.KICKED_OUT_CLEAR:
      return {
        ...state,
        kickedOut: false,
      };
    case actions.LOGIN_VALIDATOR:
      return {
        ...state,
        mqttToken: action.token,
      };
    case actions.FORBIDDEN_REQUEST:
      return {
        ...state,
        forbiddenRequest: true,
      };
    case actions.LOGOUT:
      return {
        ...initState,
        kickedOut: state.kickedOut,
        errorMessage: state.errorMessage,
      };
    case actions.STORE_UPDATE:
      return {
        ...initState,
        stores: action.payload,
      };
    case actions.TOGGLE_KYC:
      return {
        ...state,
        showKyc: false,
      };
    case actions.SHOW_KYC:
      return {
        ...state,
        showKyc: true,
      };
    case actions.CHANGE_MERCHANT_SUCCESS:
      return {
        ...state,
        selectedMerchant: action.selectedMerchant,
        selectedMerchantName: action.selectedMerchantName,
        stores: action.stores ? JSON.parse(action.stores) : null,
      };
    //REDIRECT URL
    case actions.FETCH_REDIRECT_URL:
      return initState;
    case actions.FETCHING_REDIRECT_URL:
      return {
        ...state,
        fetchingRedirectUrl: true,
      };
    case actions.FETCHED_REDIRECT_URL:
      return {
        ...state,
        fetchingRedirectUrl: false,
        redirectUrl: action.redirectUrl,
      };
    case actions.FETCH_REDIRECT_URL_FAILED:
      return {
        ...state,
        fetchingRedirectUrl: false,
        redirectUrl: null,
      };
    case storeActions.DELETED_STORE:
      return {
        ...state,
        stores: action.payload ? JSON.parse(action.payload) : null,
      };
    case actions.REDIRECT_REGISTRATION_SUCCESS:
      return {
        ...state,
        redirectRegistrationSuccessData: action.payload ? action.payload : null,
      };
    case actions.SENDING_REGISTRATION_EMAIL:
      return {
        ...state,
        redirectSendingEmail: true,
      };
    case actions.SENT_REGISTRATION_EMAIL:
      return {
        ...state,
        redirectSendingEmail: false,
        redirectSendingSuccess: true,
        redirectSendingMessage: action.payload,
      };
    case actions.RESEND_REGISTRATION_EMAIL_FAILED:
      return {
        ...state, redirectSendingEmail: false,
        redirectSendingFailed: true,
        redirectSendingMessage: action.payload,
      };
    case actions.RESET_RESEND_REGISTRATION_EMAIL:
      return {
        ...state,
        redirectSendingEmail: false,
        redirectSendingSuccess: false,
        redirectSendingFailed: false,
        redirectSendingMessage: null,
      };
      case actions.FETCHING_RESET_PASSWORD_URL:
      return {
        ...state,
        fetchingResetPassUrl: true,
      };
    case actions.FETCHED_RESET_PASSWORD_URL:
      return {
        ...state,
        fetchingResetPassUrl: false,
        resetPassUrl: action.resetPassUrl,
      };
    case actions.FETCH_RESET_PASSWORD_URL_FAILED:
      return {
        ...state,
        fetchingResetPassUrl: false,
        resetPassUrl: null,
      };
    default:
      return state;
  }
}
