// localStorage.js

export const pushToAndroid = data => {

    if (window.AndroidCommKit) {
        window.AndroidCommKit.pushToMobile(JSON.stringify(data));
    }
};

export const pushToWeb = data => {
    // add code here to receive from Android

};

window.pushToWeb = pushToWeb;