import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
// import { storePattern } from './patternRedux/store';
import Boot from './redux/boot';
// import BootPattern from './patternRedux/boot';
import Routes from './router';
import AppProvider from './AppProvider';

const App = () => (
  <Provider store={(store)}>
    <AppProvider>
      <>
        <GlobalStyles />
        <Routes />
      </>
    </AppProvider>
  </Provider>
  // process.env.REACT_APP_ENVIRONMENT === 'production' ?
  //   <Provider store={(store)}>
  //     <AppProvider>
  //       <>
  //         <GlobalStyles />
  //         <Routes />
  //       </>
  //     </AppProvider>
  //   </Provider> : <Provider store={(storePattern)}>
  //     <Provider store={(store)}>
  //       <AppProvider>
  //         <>
  //           <GlobalStyles />
  //           <Routes />
  //         </>
  //       </AppProvider>
  //     </Provider>
  //   </Provider>
);

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Boot()
    .then(() => App())
    .catch(error => console.error(error));
} else {
  // BootPattern()
  //   .then(() => App())
  //   .catch(error => console.error(error));
  Boot()
    .then(() => App())
    .catch(error => console.error(error));
}



export default App;
