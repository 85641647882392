const actions = {
  // generic actions
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  // load inventory events
  LOAD_WALLET_BALANCE: 'LOAD_WALLET_BALANCE',
  // LOAD_WITHDRAWABLE: 'LOAD_WITHDRAWABLE',
  LOADING_BALANCES: 'LOADING_BALANCES',
  BALANCES_LOADED: 'BALANCES_LOADED',
  LOADING_BALANCES_FAILED: 'LOADING_BALANCES_FAILED',
  LOADING_LIMIT: 'LOADING_LIMIT',
  LIMIT_LOADED: 'LIMIT_LOADED',
  LOADING_LIMIT_FAILED: 'LOADING_LIMIT_FAILED',
  LOAD_BANK_ACCOUNTS: 'LOAD_BANK_ACCOUNTS',
  LOADING_BANK_ACCOUNTS: 'LOADING_BANK_ACCOUNTS',
  BANK_ACCOUNTS_LOADED: 'BANK_ACCOUNTS_LOADED',
  LOADING_BANK_ACCOUNTS_FAILED: 'LOADING_BANK_ACCOUNTS_FAILED',
  // REQUEST OTP
  REQUEST_OTP: 'REQUEST_OTP',
  REQUESTING_OTP: 'REQUESTING_OTP',
  REQUESTED_OTP: 'REQUESTED_OTP',
  REQUEST_OTP_FAILED: 'REQUEST_OTP_FAILED',
  IS_ERROR_OTP: 'IS_ERROR_OTP',
  // VERIFY OTP
  VERIFY_OTP: 'VERIFY_OTP',
  VERIFYING_OTP: 'VERIFYING_OTP',
  VERIFIED_OTP: 'VERIFIED_OTP',
  RESET_VERIFIED_OTP: 'RESET_VERIFIED_OTP',
  VERIFY_OTP_FAILED: 'VERIFY_OTP_FAILED',
  // RESEND OTP
  W_RESEND_OTP: 'W_RESEND_OTP',
  W_RESENDING_OTP: 'W_RESENDING_OTP',
  W_RESEND_OTP_SUCCESS: 'W_RESEND_OTP_SUCCESS',
  W_RESEND_OTP_FAILED: 'W_RESEND_OTP_FAILED',
  W_RESET_RESEND_OTP: 'W_RESET_RESEND_OTP',
  LOAD_SUPPORTED_BANKS: 'LOAD_SUPPORTED_BANKS',
  LOADING_SUPPORTED_BANKS: 'LOADING_SUPPORTED_BANKS',
  SUPPORTED_BANK_LOADED: 'SUPPORTED_BANK_LOADED',
  LOADING_SUPPORTED_BANKS_FAILED: 'LOADING_SUPPORTED_BANKS_FAILED',
  SEND_SMS_OTP: 'SEND_SMS_OTP',
  SENDING_SMS_OTP: 'SENDING_SMS_OTP',
  SMS_OTP_SENT: 'SMS_OTP_SENT',
  SENDING_SMS_OTP_FAILED: 'SENDING_SMS_OTP_FAILED',
  // RESET_OTP: 'RESET_OTP',
  SAVE_BANK_ACCOUNT: 'SAVE_BANK_ACCOUNT',
  SAVING_BANK_ACCOUNT: 'SAVING_BANK_ACCOUNT',
  BANK_ACCOUNT_SAVED: 'BANK_ACCOUNT_SAVED',
  SAVING_BANK_ACCOUNT_FAILED: 'SAVING_BANK_ACCOUNT_FAILED',
  // RESET_SAVING_NOTIF: 'RESET_SAVING_NOTIF',
  RESEND_OTP: 'RESEND_OTP',
  RESENDING_OTP: 'RESENDING_OTP',
  RESEND_OTP_SENT: 'RESEND_OTP_SENT',
  RESENDING_OTP_FAILED: 'RESENDING_OTP_FAILED',
  UPDATE_BANK_ACCOUNT: 'UPDATE_BANK_ACCOUNT',
  UPDATING_BANK_ACCOUNT: 'UPDATING_BANK_ACCOUNT',
  BANK_ACCOUNT_UPDATED: 'BANK_ACCOUNT_UPDATED',
  UPDATING_BANK_ACCOUNT_FAILED: 'UPDATING_BANK_ACCOUNT_FAILED',
  RESET_MESSAGE_NOTIF: 'RESET_MESSAGE_NOTIF',
  REMOVE_BANK_ACCOUNT: 'REMOVE_BANK_ACCOUNT',
  REMOVING_BANK_ACCOUNT: 'REMOVING_BANK_ACCOUNT',
  BANK_ACCOUNT_REMOVED: 'BANK_ACCOUNT_REMOVED',
  REMOVING_BANK_ACCOUNT_FAILED: 'REMOVING_BANK_ACCOUNT_FAILED',
  CHANGE_FAVORITE: 'CHANGE_FAVORITE',
  CHANGING_FAVORITE: 'CHANGING_FAVORITE',
  FAVORITE_CHANGED: 'FAVORITE_CHANGED',
  CHANGING_FAVORITE_FAILED: 'CHANGING_FAVORITE_FAILED',
  // CHANGE STATUS
  CHANGE_STATUS: 'CHANGE_STATUS',
  CHANGING_STATUS: 'CHANGING_STATUS',
  STATUS_CHANGED: 'STATUS_CHANGED',
  CHANGING_STATUS_FAILED: 'CHANGING_STATUS_FAILED',
  // WITHDRAWAL_REQUEST
  GET_WITHDRAWAL_REQUEST: 'GET_WITHDRAWAL_REQUEST',
  GETTING_WITHDRAWAL_REQUEST: 'GETTING_WITHDRAWAL_REQUEST',
  GET_WITHDRAWAL_REQUEST_SUCCESS: 'GET_WITHDRAWAL_REQUEST_SUCCESS',
  GET_WITHDRAWAL_REQUEST_ERROR: 'GET_WITHDRAWAL_REQUEST_ERROR',
  GET_WITHDRAWAL_REQUEST_FAILED: 'GET_WITHDRAWAL_REQUEST_FAILED',
  // FETCH WITHDRAWAL_REQUEST
  FETCH_WITHDRAWAL_REQUEST: 'FETCH_WITHDRAWAL_REQUEST',
  FETCHING_WITHDRAWAL_REQUEST: 'FETCHING_WITHDRAWAL_REQUEST',
  FETCH_WITHDRAWAL_REQUEST_SUCCESS: 'FETCH_WITHDRAWAL_REQUEST_SUCCESS',
  FETCH_WITHDRAWAL_REQUEST_ERROR: 'FETCH_WITHDRAWAL_REQUEST_ERROR',
  FETCH_WITHDRAWAL_REQUEST_FAILED: 'FETCH_WITHDRAWAL_REQUEST_FAILED',
  // DOWNLOAD WITHDRAW
  DOWNLOAD_WITHDRAWAL: 'DOWNLOAD_WITHDRAWAL',
  DOWNLOAD_WITHDRAWAL_LOADING: 'DOWNLOAD_WITHDRAWAL_LOADING',
  DOWNLOAD_WITHDRAWAL_LOADED: 'DOWNLOAD_WITHDRAWAL_LOADED',
  DOWNLOAD_WITHDRAWAL_ERROR: 'DOWNLOAD_WITHDRAWAL_ERROR',
  DOWNLOAD_WITHDRAWAL_MESSAGE: 'DOWNLOAD_WITHDRAWAL_MESSAGE',
  // EMAIL NOTIF
  EMAIL_NOTIF: 'EMAIL_NOTIF',

  //UPDATE WITHDRAWAL STATUS
  UPDATE_WITHDRAWAL_STATUS: 'UPDATE_WITHDRAWAL_STATUS',
  UPDATING_WITHDRAWAL_STATUS: 'UPDATING_WITHDRAWAL_STATUS',
  UPDATED_WITHDRAWAL_STATUS: 'UPDATED_WITHDRAWAL_STATUS',
  UPDATING_FAILED_WITHDRAWAL_STATUS: 'UPDATING_FAILED_WITHDRAWAL_STATUS',

  resetEmailNotif: () => ({
    type: actions.EMAIL_NOTIF,
    payload: null,
  }),
  getWithdrawalRequest: () => ({
    type: actions.GET_WITHDRAWAL_REQUEST,
  }),
  loadBalance: () => ({
    type: actions.LOAD_WALLET_BALANCE,
  }),
  loadBankAccounts: () => ({
    type: actions.LOAD_BANK_ACCOUNTS,
  }),
  requestOtp: (payload) => ({
    type: actions.REQUEST_OTP,
    payload: payload,
  }),
  resetingOtp: () => ({
    type: actions.REQUESTED_OTP,
    payload: null,
    isErrorOtp: false,
  }),
  verifyOtp: (signature, payload, accountType) => ({
    type: actions.VERIFY_OTP,
    payload: payload,
    signature: signature,
    accountType: accountType,
  }),
  resetVerifyOtp: () => ({
    type: actions.RESET_VERIFIED_OTP,
    // isErrorVerifyOtp: false,
  }),
  loadSupportedBanks: () => ({
    type: actions.LOAD_SUPPORTED_BANKS,
  }),
  smsRequestBankAccount: (payload) => ({
    type: actions.SEND_SMS_OTP,
    payload: payload,
  }),
  // resetOtp: () => ({
  //   type: actions.RESET_OTP,
  // }),
  saveBankAccount: (payload) => ({
    type: actions.SAVE_BANK_ACCOUNT,
    payload: payload,
  }),
  // resetSavingNotif: () => ({
  //   type: actions.RESET_SAVING_NOTIF,
  // }),
  resendOtp: (payload) => ({
    type: actions.RESEND_OTP,
    payload: payload,
  }),
  wResendOtp: (payload) => ({
    type: actions.W_RESEND_OTP,
    payload: payload,
  }),
  resetResendOtp: () => ({
    type: actions.W_RESET_RESEND_OTP,
  }),
  updateBankAccount: (payload) => ({
    type: actions.UPDATE_BANK_ACCOUNT,
    payload: payload,
  }),
  removeBankAccount: (payload) => ({
    type: actions.REMOVE_BANK_ACCOUNT,
    payload: payload,
  }),
  changeFavorite: (payload) => ({
    type: actions.CHANGE_FAVORITE,
    payload: payload,
  }),
  resetMessageNotif: () => ({
    type: actions.RESET_MESSAGE_NOTIF,
  }),
  downloadWithdrawal: (payload) => ({
    type: actions.DOWNLOAD_WITHDRAWAL,
    payload: payload,
  }),
  resetDownloadWithdrawalMessage: () => ({
    type: actions.DOWNLOAD_WITHDRAWAL_MESSAGE,
    payload: null,
  }),
  fetchWithdrawalRequest: (payload) => ({
    type: actions.FETCH_WITHDRAWAL_REQUEST,
    payload,
  }),
  updateStatus: (payload) => ({
    type: actions.UPDATE_WITHDRAWAL_STATUS,
    payload,
  }),
  clearUpdateStatus: () => ({
    type: actions.UPDATED_WITHDRAWAL_STATUS,
    payload: null,
  }),
  changeStatus: (payload) => ({
    type: actions.CHANGE_STATUS,
    payload: payload,
  }),
};
export default actions; 