import actions from './actions';

const initState = {
  merchantLoading: false,
  merchantInfo: null,
  merchantErrorMessage: null,
  merchantLogo: null,
  loadingPchannel: false,
  merchantPchannel: null,
  bankAccount: null,
  // MERCHANT USERS
  merchantUsers: null,
  merchantUsersPagination: null,
  selectedMerchantUser: null,
  merchantUserUpdatedData: null,
  addMechantUserError: null,
  addMechantUserSuccess: false,
  editMechantUserSuccess: false,
  addingMerchant: false,
  loadingFilteredRole: false,
  filteredRole: null,
  payloadCore: null,
  selectedId: null,
  rolePermission: null,
  openRolePermissionDialog: false,
  updateMerchantError: null,
  merchantUserDeleted: false,
  merchantUserPasswordReset: false,
  fetchingMerchantUsers: false,
  loadingMoreMerchantUsers: false,
  allPchannels: null,
  updatingMerchantDetails: false,
  updatedMerchantDetails: false,
  updatedMessage: null,
  merchantStores: null,
  downloadingHistory: false,
  downloadMessage: null,
  downloadData: null,
  downloadingStoreTxn: false,
  downloadStoreTxnMessage: null,
  downloadTransaction: null,
  downloadStoreId: null,
  riskProfileData: null,
  gettingKyc: false,
  kycQualified: null,
  kycApplication: null,
  kycDocs: [],
  kycRequirements: [],
  postingKyc: false,
  postedKyc: false,
  previewingKycDocs: false,
  previewedKycDocs: null,
  mqttResult: false,
  referralLinkdata: null,
  merchLogo: [],
  uploadingLogo: false,
  uploadedLogo: false,
  uploadLogoMessage: null,
  reuploadAsking: false,
  reuploadAsked: false,
  reuploadAskingResult: null,
  approvingKycDocs: false,
  approvedKycDocs: false,
  approvingResult: null,
  updatingMerchantChannels: false,
  updatedMerchantChannels: false,
  updatedMerchChannelMsg: null,
  redirectFetchingImg: false,
  redirectImg: null,
  redirectStoreChannels: null,
  redirectGrabToken: null,
  redirectErrorMessage: null,
  redirectFetchingData: false,
  redirectCustomerData: null,
  redirectPaymentRequesting: false,
  redirectPaymentRequested: null,
  redirectPaymentMessage: null,
  redirectPaymentSuccess: false,
  resettingMerchantUserPassword: false,
  merchantPasswordResetData: null,
  newMerchantUserPasswordReset: false,
  resettingTokenData: false,
  hasResetTokenData: null,
  redirectStoreInfo: null,
  redirectMonthlyLimit: null,
  redirectReachLimit: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.LOAD_MERCHANT:
      return initState;
    case actions.LOADING_MERCHANT_INFO:
      return {
        ...state,
        merchantLoading: true,
        merchantInfo: null,
      };
    case actions.MERCHANT_INFO_LOADED:
      return {
        ...state,
        merchantLoading: false,
        merchantInfo: action.merchantInfo,
        merchantLogo: action.merchantLogo,
        bankAccount: action.bankAccount,
      };
    case actions.LOAD_MERCHANT_INFO_FAILED:
      return {
        ...state,
        merchantLoading: false,
        merchantInfo: null,
        merchantErrorMessage: '', // payload err here
      };
    //PCHANNEL
    // case actions.LOAD_MERCHANT_PCHANNEL:
    //   return initState;
    case actions.LOADING_MERCHANT_PCHANNEL:
      return {
        ...state,
        loadingPchannel: true,
      };
    case actions.MERCHANT_PCHANNEL_LOADED:
      return {
        ...state,
        loadingPchannel: false,
        merchantPchannel: action.merchantPchannel,
      };
    case actions.LOAD_MERCHANT_PCHANNEL_FAILED:
      return {
        ...state,
        loadingPchannel: false,
        merchantPchannel: null,
      };
    case actions.MERCHANT_USERS_FETCHED:
      // console.log('MERCHANT_USERS_FETCHED1', action.merchantUsersData);
      return {
        ...state,
        merchantUsers: action.merchantUsersData,
        merchantUsersPagination: action.pagination,
        fetchingMerchantUsers: false,
        loadingMoreMerchantUsers: false,
      };
    case actions.FETCHING_MERCHANT_USERS:
      return {
        ...state,
        fetchingMerchantUsers: true,
        selectedMerchantUser: null,
        merchantUsers: null,
      };
    case actions.LOADING_MORE_MERCHANT_USERS:
      return {
        ...state,
        loadingMoreMerchantUsers: true,
      };
    case actions.SET_MERCHANT_USER_DATA:
      return {
        ...state,
        selectedMerchantUser: action.payload,
      };
    case actions.ADD_MERCHANT_USER_FAILED:
      return {
        ...state,
        addMechantUserError: action.payload,
        addingMerchant: false,
        addMechantUserSuccess: false,
      };
    case actions.MERCHANT_USER_ADDED:
      return {
        ...state,
        addingMerchant: false,
        addMechantUserSuccess: true,
      };
    case actions.FILTERED_ROLE_FETCHED:
      return {
        ...state,
        filteredRole: action.payload,
        loadingFilteredRole: false,
      };
    case actions.ROLE_PERMISSION_FETCHED:
      return {
        ...state,
        rolePermission: action.payload,
        payloadCore: action.payloadCore,
        selectedId: action.selectedId,
      };
    // case actions.OPEN_ROLE_PERMISSION_DIALOG:
    //   return {
    //     ...state,
    //     openRolePermissionDialog: !state.openRolePermissionDialog,
    //   };
    case actions.RESET_ROLE_PERMISSION_DATA:
      return {
        ...state,
        rolePermission: null,
        selectedId: null,
      };
    case actions.FETCHING_ALL_FILTERED_ROLE:
      return {
        ...state,
        loadingFilteredRole: true,
      };
    case actions.RESET_ADD_USER_FORM:
      return {
        ...state,
        addingMerchant: false,
        addMechantUserSuccess: false,
        addMechantUserError: null,
      };
    case actions.RESET_EDIT_USER_FORM:
      return {
        ...state,
        editUserForm: false,
        editMechantUserSuccess: false,
        updateMerchantError: null,
      };
    case actions.ADDING_MERCHANT_USER:
      return {
        ...state,
        addingMerchant: true,
      };
    case actions.UPDATE_MERCHANT_USER_FAILED:
      return {
        ...state,
        updateMerchantError: action.updateError,
        editMechantUserSuccess: false,
      };
    case actions.UPDATE_MERCHANT_USER_SUCCESS:
      return {
        ...state,
        editMechantUserSuccess: true,
      };
    case actions.MERCHANT_USER_DEACTIVATED:
      return {
        ...state,
        merchantUserDeleted: true,
      };
    case actions.DEACTIVATE_MERCHANT_USER_RESET:
      return {
        ...state,
        merchantUserDeleted: false,
      };
    case actions.MERCHANT_USER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        merchantUserPasswordReset: true,
      };
    case actions.RESET_MERCHANT_USER_PASSWORD_RESET:
      return {
        ...state,
        merchantUserPasswordReset: false,
      };
    case actions.MERCHANT_USER_UPDATED:
      const updatedUser = action.merchantUserUpdatedData;
      const merchantUsersList = state.merchantUsers;
      let updatedSelectedUser;
      for (let i = 0; i < merchantUsersList.length; i++) {
        const element = merchantUsersList[i];
        if (updatedUser.Id === element.Id) {
          merchantUsersList[i].firstName = updatedUser.firstName;
          merchantUsersList[i].lastName = updatedUser.lastName;
          merchantUsersList[i].email = updatedUser.email;
          updatedSelectedUser = merchantUsersList[i];
        }
      }
      Object.assign(state.selectedMerchantUser, updatedSelectedUser);
      return {
        ...state,
        merchantUsers: merchantUsersList,
        selectedMerchantUser: {
          ...state.selectedMerchantUser,
          updatedSelectedUser,
        },
      };
    case actions.GETTING_ALL_PCHANNELS:
      return {
        ...state,
        allPchannels: null,
      };
    case actions.GET_ALL_PCHANNELS_SUCCESS:
      return {
        ...state,
        allPchannels: action.payload,
      };
    case actions.GET_ALL_PCHANNELS_FAILED:
      return {
        ...state,
        allPchannels: null,
      };
    case actions.EDITING_MERCHANT_DETAILS:
      return {
        ...state,
        updatingMerchantDetails: true,
      };
    case actions.MERCHANT_DETAILS_EDITED:
      return {
        ...state,
        updatingMerchantDetails: false,
        updatedMerchantDetails: true,
        updatedMessage: 'Successfully updated merchant details',
        merchantInfo: action.result.merchantDetails,
      };
    case actions.EDIT_MERCHANT_DETAILS_FAILED:
      return {
        ...state,
        updatingMerchantDetails: false,
        updatedMerchantDetails: true,
        updatedMessage: 'Failed to update merchant details',
      };
    case actions.RESET_MESSAGE_NOTIF:
      return {
        ...state,
        updatingMerchantDetails: false,
        updatedMerchantDetails: false,
        updatedMessage: null,
      };
    case actions.DOWNLOADING_HISTORY:
      return {
        ...state,
        downloadingHistory: true,
      };
    case actions.DOWNLOAD_HISTORY_SUCCESS:
      return {
        ...state,
        downloadingHistory: false,
        downloadData: action.data,
      };
    case actions.RESET_DOWNLOAD_HISTORY:
      return {
        ...state,
        downloadingHistory: false,
        downloadData: null,
        downloadMessage: null,
      };
    case actions.DOWNLOAD_HISTORY_FAILED:
      return {
        ...state,
        downloadingHistory: false,
        downloadMessage: action.message,
      };
    // DOWNLOAD STORE TXN
    case actions.DOWNLOADING_STORE_TXN:
      return {
        ...state,
        downloadingStoreTxn: true,
        downloadStoreId: action.result,
      };
    case actions.DOWNLOAD_STORE_TXN_SUCCESS:
      return {
        ...state,
        downloadingStoreTxn: false,
        downloadTransaction: action.data,
      };
    case actions.RESET_DOWNLOADED_STORE_TXN:
      return {
        ...state,
        downloadingStoreTxn: false,
        downloadTransaction: null,
        downloadStoreTxnMessage: null,
        downloadStoreId: null,
      };
    case actions.DOWNLOAD_STORE_TXN_FAILED:
      return {
        ...state,
        downloadingStoreTxn: false,
        downloadStoreTxnMessage: action.message,
      };
    case actions.FETCHING_RISK_PROFILE_SUCCESS:
      return {
        ...state,
        riskProfileData: action.riskProfileData,
      };
    case actions.GETTING_KYC_QUALIFIED:
      return {
        ...state,
        gettingKyc: true,
      };
    case actions.GOT_KYC_QUALIFIED:
      return {
        ...state,
        gettingKyc: false,
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
        kycRequirements: action.requirements,
      };
    case actions.GETTING_KYC_QUALIFIED_FAILED:
      return {
        ...state,
        gettingKyc: false,
      };
    case actions.POSTING_KYC_DOCS:
      return {
        ...state,
        postingKyc: true,
      };
    case actions.POSTED_KYC_DOCS:
      return {
        ...state,
        postingKyc: false,
        postedKyc: true,
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
        mqttResult: false,
      };
    case actions.MQTT_POSTING_RESULT:
      return {
        ...state,
        mqttResult: true,
      };
    case actions.RESET_KYC_POST:
      return {
        ...state,
        postingKyc: false,
        postedKyc: false,
        mqttResult: false,
      };
    case actions.POSTING_KYC_DOCS_FAILED:
      return {
        ...state,
        postingKyc: false,
        postedKyc: false,
        mqttResult: false,
      };
    case actions.PREVIEWING_KYC_DOCS:
      return {
        ...state,
        previewingKycDocs: true,
      };
    case actions.PREVIEWED_KYC_DOCS:
      return {
        ...state,
        previewingKycDocs: false,
        previewedKycDocs: action.data,
      };
    case actions.PREVIEWING_KYC_DOCS_FAILED:
      return {
        ...state,
        previewingKycDocs: false,
      };
    case actions.RESET_KYC_PREVIEW:
      return {
        ...state,
        previewingKycDocs: false,
        previewedKycDocs: null,
      };
    case actions.FETCH_MERCHANT_REFERRAL_LINK_SUCCESS:
      return {
        ...state,
        referralLinkdata: action.referralLinkdata,
      };
    // UPLOAD LOGO
    case actions.UPLOADING_MERCHANT_LOGO:
      return {
        ...state,
        uploadingLogo: true,
      };
    case actions.UPLOADED_MERCHANT_LOGO:
      return {
        ...state,
        uploadingLogo: false,
        uploadedLogo: true,
        merchLogo: action.data ? action.data.merchLogo : [],
        // merchantLogo: action.merchantLogo,
        uploadLogoMessage: action.message,
      };
    case actions.RESET_MERCHANT_LOGO_UPLOAD:
      return {
        ...state,
        uploadingLogo: false,
        uploadedLogo: false,
        uploadLogoMessage: null,
      };
    case actions.UPLOAD_MERCHANT_LOGO_FAILED:
      return {
        ...state,
        uploadingLogo: false,
        uploadedLogo: false,
        uploadLogoMessage: action.message,
      };
    case actions.ASKING_FOR_REUPLOAD:
      return {
        ...state,
        reuploadAsking: true,
      };
    case actions.ASKED_FOR_REUPLOAD:
      return {
        ...state,
        reuploadAsking: false,
        reuploadAsked: true,
        reuploadAskingResult: 'Reupload request sent',
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
      };
    case actions.ASKING_FOR_REUPLOAD_FAILED:
      return {
        ...state,
        reuploadAsking: false,
        reuploadAsked: true,
      };
    case actions.RESET_ASK_REUPLOAD:
      return {
        ...state,
        reuploadAsking: false,
        reuploadAsked: false,
        reuploadAskingResult: null,
      };
    case actions.APPROVING_KYC_DOCS:
      return {
        ...state,
        approvingKycDocs: true,
      };
    case actions.APPROVED_KYC_DOCS:
      return {
        ...state,
        approvingKycDocs: false,
        approvedKycDocs: true,
        approvingResult: 'KYC documents approved',
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
      };
    case actions.APPROVING_KYC_DOCS_FAILED:
      return {
        ...state,
        approvingKycDocs: false,
        approvedKycDocs: true,
      };
    case actions.RESET_APPROVING:
      return {
        ...state,
        approvingKycDocs: false,
        approvedKycDocs: false,
        approvingResult: null,
      };
    // UPDATE MERCHANT CHANNELS
    case actions.UPDATING_MERCHANT_CHANNELS:
      return {
        ...state,
        updatingMerchantChannels: true,
      };
    case actions.MERCHANT_CHANNELS_UPDATED:
      return {
        ...state,
        updatingMerchantChannels: false,
        updatedMerchantChannels: true,
        updatedMerchChannelMsg: action.message,
        // merchantInfo: action.result.merchantDetails,
      };
    case actions.UPDATE_MERCHANT_CHANNELS_FAILED:
      return {
        ...state,
        updatingMerchantChannels: false,
        updatedMerchantChannels: true,
        updatedMerchChannelMsg: action.message,
      };
    case actions.FETCHING_MERCHANT_IMAGE:
      return {
        ...state,
        redirectFetchingImg: true,
      };
    case actions.FETCHED_MERCHANT_IMAGE:
      return {
        ...state,
        redirectFetchingImg: false,
        redirectImg: action.merchantLogo,
        redirectStoreChannels: action.storeChannels,
        redirectGrabToken: action.grabToken,
        redirectStoreInfo: action.storeInfo,
        redirectMonthlyLimit: action.monthlyLimit,
        redirectReachLimit: action.reachLimit,
      };
    case actions.FETCH_MERCHANT_IMAGE_FAILED:
      return {
        ...state,
        redirectFetchingImg: false,
        redirectImg: null,
        redirectStoreChannels: null,
        redirectGrabToken: null,
        redirectErrorMessage: action.payload,
        redirectStoreInfo: null,
        redirectMonthlyLimit: null,
        redirectReachLimit: null,
        
      };
    case actions.FETCHING_CUSTOMER_DATA:
      return {
        ...state,
        redirectFetchingData: true,
      };
    case actions.FETCHED_CUSTOMER_DATA:
      return {
        ...state,
        redirectFetchingData: false,
        redirectCustomerData: action.payload,
      };
    case actions.FETCH_CUSTOMER_DATA_FAILED:
      return {
        ...state,
        redirectFetchingData: false,
      };
    case actions.PAYMENT_TRANSACTION_REQUESTING:
      return {
        ...state,
        redirectPaymentRequesting: true,
      };
    case actions.PAYMENT_TRANSACTION_REQUESTED:
      return {
        ...state,
        redirectPaymentRequesting: false,
        redirectPaymentRequested: action.payload,
        redirectPaymentSuccess: true,
      };
    case actions.PAYMENT_TRANSACTION_REQUEST_FAILED:
      // console.log('CHECK RESULT MESSAGE',action.payload);
      return {
        ...state,
        redirectPaymentRequesting: false,
        redirectPaymentMessage: action.payload,
        redirectPaymentSuccess: false,
      };
    case actions.RESET_PAYMENT_TRANSACTION_REQUEST:
      return {
        ...state,
        redirectPaymentRequesting: false,
        redirectPaymentMessage: null,
        redirectPaymentRequested: null,
        redirectPaymentSuccess: false,
      };
    case actions.RESETTING_TOKEN_DATA:
      return {
        ...state,
        resettingTokenData: true,
      };
    case actions.RESET_TOKEN_DATA_SUCCESS:
      return {
        ...state,
        resettingTokenData: false,
        hasResetTokenData: action.result,
      };
    case actions.RESET_TOKEN_DATA_FAILED:
      return {
        ...state,
        resettingTokenData: false,
        hasResetTokenData: null,
      };
    case actions.NEW_RESETTING_MERCHANT_USER_PASSWORD:
      return {
        ...state,
        resettingMerchantUserPassword: true,
      };
    case actions.NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        newMerchantUserPasswordReset: true,
        merchantPasswordResetData: action.result,
      };
    case actions.NEW_RESET_MERCHANT_USER_PASSWORD_RESET:
      return {
        ...state,
        newMerchantUserPasswordReset: true,
      };
    case actions.NEW_MERCHANT_USER_PASSWORD_RESET_FAILED:
      return {
        ...state,
        resettingMerchantUserPassword: false,
        newMerchantUserPasswordReset: false,
      };
    default:
      return state;
  }
}
