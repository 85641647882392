const actions = {
  LOAD_MERCHANT_INFO: 'LOAD_MERCHANT_INFO',
  LOAD_MERCHANT_INFO_FAILED: 'LOAD_MERCHANT_INFO_FAILED',
  LOADING_MERCHANT_INFO: 'LOADING_MERCHANT_INFO',
  MERCHANT_INFO_LOADED: 'MERCHANT_INFO_LOADED',
  //Merchant Payment Channels
  LOAD_MERCHANT_PCHANNEL: 'LOAD_MERCHANT_PCHANNEL',
  LOAD_MERCHANT_PCHANNEL_FAILED: 'LOAD_MERCHANT_PCHANNEL_FAILED',
  LOADING_MERCHANT_PCHANNEL: 'LOADING_MERCHANT_PCHANNEL',
  MERCHANT_PCHANNEL_LOADED: 'MERCHANT_PCHANNEL_LOADED',
  // Fetch Merchant Users
  FETCH_MERCHANT_USERS: 'FETCH_MERCHANT_USERS',
  FETCHING_MERCHANT_USERS: 'FETCHING_MERCHANT_USERS',
  LOADING_MORE_MERCHANT_USERS: 'LOADING_MORE_MERCHANT_USERS',
  MERCHANT_USERS_FETCHED: 'MERCHANT_USERS_FETCHED',
  FETCH_MERCHANT_USERS_FAILED: 'FETCH_MERCHANT_USERS_FAILED',
  // SET MERCHANT USER VALUE
  SET_MERCHANT_USER_DATA: 'SET_MERCHANT_USER_DATA',
  // UPDATE USER IN USER PORTAL
  UPDATE_MERCHANT_USER: 'UPDATE_MERCHANT_USER',
  UPDATING_MERCHANT_USER: 'UPDATING_MERCHANT_USER',
  MERCHANT_USER_UPDATED: 'MERCHANT_USER_UPDATED',
  UPDATE_MERCHANT_USER_FAILED: 'UPDATE_MERCHANT_USER_FAILED',
  // ADD USER IN USER PORTAL
  ADD_MERCHANT_USER: 'ADD_MERCHANT_USER',
  ADDING_MERCHANT_USER: 'ADDING_MERCHANT_USER',
  MERCHANT_USER_ADDED: 'MERCHANT_USER_ADDED',
  RESET_ADD_USER_FORM: 'RESET_ADD_USER_FORM',
  ADD_MERCHANT_USER_FAILED: 'ADD_MERCHANT_USER_FAILED',
  // FETCH FILTERED ROLE IN USER PORTAL
  FETCH_ALL_FILTERED_ROLE: 'FETCH_ALL_FILTERED_ROLE',
  FETCHING_ALL_FILTERED_ROLE: 'FETCHING_ALL_FILTERED_ROLE',
  FILTERED_ROLE_FETCHED: 'FILTERED_ROLE_FETCHED',
  FETCH_ALL_FILTERED_ROLE_FAILED: 'FETCH_ALL_FILTERED_ROLE_FAILED',
  // FETCH AUTH MANAGMENT ROLE
  FETCH_AUTH_MANAGEMENT_ROLE: 'FETCH_AUTH_MANAGEMENT_ROLE',
  FETCHING_AUTH_MANAGEMENT_ROLE: 'FETCHING_AUTH_MANAGEMENT_ROLE',
  AUTH_MANAGEMENT_ROLE_FETCHED: 'AUTH_MANAGEMENT_ROLE_FETCHED',
  FETCH_AUTH_MANAGEMENT_ROLE_FAILED: 'FETCH_AUTH_MANAGEMENT_ROLE_FAILED',
  // FETCH ROLE PERMISSION
  FETCH_ROLE_PERMISSION: 'FETCH_ROLE_PERMISSION',
  FETCHING_ROLE_PERMISSION: 'FETCHING_ROLE_PERMISSION',
  ROLE_PERMISSION_FETCHED: 'ROLE_PERMISSION_FETCHED',
  FETCH_ROLE_PERMISSION_FAILED: 'FETCH_ROLE_PERMISSION_FAILED',
  OPEN_ROLE_PERMISSION_DIALOG: 'OPEN_ROLE_PERMISSION_DIALOG',
  RESET_ROLE_PERMISSION_DATA: 'RESET_ROLE_PERMISSION_DATA',

  // SAVE AUTH MANAGEMENT
  SAVE_AUTH_MANAGEMENT: 'SAVE_AUTH_MANAGEMENT',
  SAVING_AUTH_MANAGEMENT: 'SAVING_AUTH_MANAGEMENT',
  AUTH_MANAGEMENT_SAVED: 'AUTH_MANAGEMENT_SAVED',
  SAVE_AUTH_MANAGEMENT_FAILED: 'SAVE_AUTH_MANAGEMENT_FAILED',
  

  // DEACTIVATE USER IN USER PORTAL
  DEACTIVATE_MERCHANT_USER: 'DEACTIVATE_MERCHANT_USER',
  DEACTIVATING_MERCHANT_USER: 'DEACTIVATING_MERCHANT_USER',
  MERCHANT_USER_DEACTIVATED: 'MERCHANT_USER_DEACTIVATED',
  RESET_EDIT_USER_FORM: 'RESET_EDIT_USER_FORM',
  UPDATE_MERCHANT_USER_SUCCESS: 'UPDATE_MERCHANT_USER_SUCCESS',
  DEACTIVATE_MERCHANT_USER_FAILED: 'DEACTIVATE_MERCHANT_USER_FAILED',
  DEACTIVATE_MERCHANT_USER_RESET: 'DEACTIVATE_MERCHANT_USER_RESET',
  // RESET USER IN USER PORTAL
  RESET_MERCHANT_USER_PASSWORD: 'RESET_MERCHANT_USER_PASSWORD',
  RESETTING_MERCHANT_USER_PASSWORD: 'RESETTING_MERCHANT_USER_PASSWORD',
  MERCHANT_USER_PASSWORD_RESET_SUCCESS: 'MERCHANT_USER_PASSWORD_RESET_SUCCESS',
  MERCHANT_USER_PASSWORD_RESET_FAILED: 'MERCHANT_USER_PASSWORD_RESET_FAILED',
  MERCHANT_USER_PASSWORD_RESET_ERROR: 'MERCHANT_USER_PASSWORD_RESET_ERROR',
  RESET_MERCHANT_USER_PASSWORD_RESET: 'RESET_MERCHANT_USER_PASSWORD_RESET',
  // NEW RESET PASSWORD
  NEW_RESET_MERCHANT_USER_PASSWORD: 'NEW_RESET_MERCHANT_USER_PASSWORD',
  NEW_RESETTING_MERCHANT_USER_PASSWORD: 'NEW_RESETTING_MERCHANT_USER_PASSWORD',
  NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS: 'NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS',
  NEW_MERCHANT_USER_PASSWORD_RESET_FAILED: 'NEW_MERCHANT_USER_PASSWORD_RESET_FAILED',
  NEW_MERCHANT_USER_PASSWORD_RESET_ERROR: 'NEW_MERCHANT_USER_PASSWORD_RESET_ERROR',
  NEW_RESET_MERCHANT_USER_PASSWORD_RESET: 'NEW_RESET_MERCHANT_USER_PASSWORD_RESET',
  // GET ALL PCHANNELS
  GET_ALL_PCHANNELS: 'GET_ALL_PCHANNELS',
  GETTING_ALL_PCHANNELS: 'GETTING_ALL_PCHANNELS',
  GET_ALL_PCHANNELS_SUCCESS: 'GET_ALL_PCHANNELS_SUCCESS',
  GET_ALL_PCHANNELS_FAILED: 'GET_ALL_PCHANNELS_FAILED',
  // EDIT MERCHANT DETAILS
  EDIT_MERCHANT_DETAILS: 'EDIT_MERCHANT_DETAILS',
  EDITING_MERCHANT_DETAILS: 'EDITING_MERCHANT_DETAILS',
  MERCHANT_DETAILS_EDITED: 'MERCHANT_DETAILS_EDITED',
  EDIT_MERCHANT_DETAILS_FAILED: 'EDIT_MERCHANT_DETAILS_FAILED',
  RESET_MESSAGE_NOTIF: 'RESET_MESSAGE_NOTIF',
  // DOWNLOAD HISTORY
  DOWNLOAD_HISTORY: 'DOWNLOAD_HISTORY',
  DOWNLOADING_HISTORY: 'DOWNLOADING_HISTORY',
  DOWNLOAD_HISTORY_SUCCESS: 'DOWNLOAD_HISTORY_SUCCESS',
  DOWNLOAD_HISTORY_FAILED: 'DOWNLOAD_HISTORY_FAILED',
  RESET_DOWNLOAD_HISTORY: 'RESET_DOWNLOAD_HISTORY',
  // DOWNLOAD STORE TRANSACTIONS
  DOWNLOAD_STORE_TXN: 'DOWNLOAD_STORE_TXN',
  DOWNLOADING_STORE_TXN: 'DOWNLOADING_STORE_TXN',
  DOWNLOAD_STORE_TXN_SUCCESS: 'DOWNLOAD_STORE_TXN_SUCCESS',
  DOWNLOAD_STORE_TXN_FAILED: 'DOWNLOAD_STORE_TXN_FAILED',
  RESET_DOWNLOADED_STORE_TXN: 'RESET_DOWNLOADED_STORE_TXN',
  // FETCH RISK PROFILE
  FETCH_RISK_PROFILE: 'FETCH_RISK_PROFILE',
  FETCHING_RISK_PROFILE: 'FETCHING_RISK_PROFILE',
  FETCHING_RISK_PROFILE_SUCCESS: 'FETCHING_RISK_PROFILE_SUCCESS',
  FETCHING_RISK_PROFILE_FAILED: 'FETCHING_RISK_PROFILE_FAILED',
  // GET KYC QUALIFIED
  GET_KYC_QUALIFIED: 'GET_KYC_QUALIFIED',
  GETTING_KYC_QUALIFIED: 'GETTING_KYC_QUALIFIED',
  GOT_KYC_QUALIFIED: 'GOT_KYC_QUALIFIED',
  GETTING_KYC_QUALIFIED_FAILED: 'GETTING_KYC_QUALIFIED_FAILED',
  // POST KYC DOCS
  POST_KYC_DOCS: 'POST_KYC_DOCS',
  POSTING_KYC_DOCS: 'POSTING_KYC_DOCS',
  POSTED_KYC_DOCS: 'POSTED_KYC_DOCS',
  MQTT_POSTING_RESULT: 'MQTT_POSTING_RESULT',
  POSTING_KYC_DOCS_FAILED: 'POSTING_KYC_DOCS_FAILED',
  RESET_KYC_POST: 'RESET_KYC_POST',
  RELOAD_KYC: 'RELOAD_KYC',
  // PREVIEW KYC DOCS
  PREVIEW_KYC_DOCS: 'PREVIEW_KYC_DOCS',
  PREVIEWING_KYC_DOCS: 'PREVIEWING_KYC_DOCS',
  PREVIEWED_KYC_DOCS: 'PREVIEWED_KYC_DOCS',
  PREVIEWING_KYC_DOCS_FAILED: 'PREVIEWING_KYC_DOCS_FAILED',
  RESET_KYC_PREVIEW: 'RESET_KYC_PREVIEW',
  // FETCH MERCHANT REFERRAL LINK
  FETCH_MERCHANT_REFERRAL_LINK: 'FETCH_MERCHANT_REFERRAL_LINK',
  FETCHING_MERCHANT_REFERRAL_LINK: 'FETCHING_MERCHANT_REFERRAL_LINK',
  FETCH_MERCHANT_REFERRAL_LINK_SUCCESS: 'FETCH_MERCHANT_REFERRAL_LINK_SUCCESS',
  FETCH_MERCHANT_REFERRAL_LINK_FAILED: 'FETCH_MERCHANT_REFERRAL_LINK_FAILED',
  // UPLOAD LOGO
  UPLOAD_MERCHANT_LOGO: 'UPLOAD_MERCHANT_LOGO',
  UPLOADING_MERCHANT_LOGO: 'UPLOADING_MERCHANT_LOGO',
  UPLOADED_MERCHANT_LOGO: 'UPLOADED_MERCHANT_LOGO',
  UPLOAD_MERCHANT_LOGO_FAILED: 'UPLOAD_MERCHANT_LOGO_FAILED',
  RESET_MERCHANT_LOGO_UPLOAD: 'RESET_MERCHANT_LOGO_UPLOAD',
  // ASK REUPLOAD KYC DOCS
  ASK_FOR_REUPLOAD: 'ASK_FOR_REUPLOAD',
  ASKING_FOR_REUPLOAD: 'ASKING_FOR_REUPLOAD',
  ASKED_FOR_REUPLOAD: 'ASKED_FOR_REUPLOAD',
  ASKING_FOR_REUPLOAD_FAILED: 'ASKING_FOR_REUPLOAD_FAILED',
  RESET_ASK_REUPLOAD: 'RESET_ASK_REUPLOAD',
  // APPROVE KYC DOCS
  APPROVE_KYC_DOCS: 'APPROVE_KYC_DOCS',
  APPROVING_KYC_DOCS: 'APPROVING_KYC_DOCS',
  APPROVED_KYC_DOCS: 'APPROVED_KYC_DOCS',
  APPROVING_KYC_DOCS_FAILED: 'APPROVING_KYC_DOCS_FAILED',
  RESET_APPROVING: 'RESET_APPROVING',
  // UPDATE MERCHANT CHANNELS
  UPDATE_MERCHANT_CHANNELS: 'UPDATE_MERCHANT_CHANNELS',
  UPDATING_MERCHANT_CHANNELS: 'UPDATING_MERCHANT_CHANNELS',
  MERCHANT_CHANNELS_UPDATED: 'MERCHANT_CHANNELS_UPDATED',
  UPDATE_MERCHANT_CHANNELS_FAILED: 'UPDATE_MERCHANT_CHANNELS_FAILED',
  // FETCH MERCHANT IMAGE
  FETCH_MERCHANT_IMAGE: 'FETCH_MERCHANT_IMAGE',
  FETCHING_MERCHANT_IMAGE: 'FETCHING_MERCHANT_IMAGE',
  FETCHED_MERCHANT_IMAGE: 'FETCHED_MERCHANT_IMAGE',
  FETCH_MERCHANT_IMAGE_FAILED: 'FETCH_MERCHANT_IMAGE_FAILED',
  // FETCH CUSTOMER DATA
  FETCH_CUSTOMER_DATA: 'FETCH_CUSTOMER_DATA',
  FETCHING_CUSTOMER_DATA: 'FETCHING_CUSTOMER_DATA',
  FETCHED_CUSTOMER_DATA: 'FETCHED_CUSTOMER_DATA',
  FETCH_CUSTOMER_DATA_FAILED: 'FETCH_CUSTOMER_DATA_FAILED',
  // PAYMENT TRANSACTION REQUEST
  PAYMENT_TRANSACTION_REQUEST: 'PAYMENT_TRANSACTION_REQUEST',
  PAYMENT_TRANSACTION_REQUESTING: 'PAYMENT_TRANSACTION_REQUESTING',
  PAYMENT_TRANSACTION_REQUESTED: 'PAYMENT_TRANSACTION_REQUESTED',
  PAYMENT_TRANSACTION_REQUEST_FAILED: 'PAYMENT_TRANSACTION_REQUEST_FAILED',
  RESET_PAYMENT_TRANSACTION_REQUEST: 'RESET_PAYMENT_TRANSACTION_REQUEST',
  // RESET TOKEN DATA
  RESET_TOKEN_DATA: 'RESET_TOKEN_DATA',
  RESETTING_TOKEN_DATA: 'RESETTING_TOKEN_DATA',
  RESET_TOKEN_DATA_SUCCESS: 'RESET_TOKEN_DATA_SUCCESS',
  RESET_TOKEN_DATA_FAILED: 'RESET_TOKEN_DATA_FAILED',

  loadMerchantInfo: () => ({
    type: actions.LOAD_MERCHANT_INFO,
  }),
  loadPChannels: () => ({
    type: actions.LOAD_MERCHANT_PCHANNEL,
    // payload: payload,
  }),
  fetchMerchantUsers: (payload) => ({
    type: actions.FETCH_MERCHANT_USERS,
    payload: payload,
  }),
  setMerchantUserData: (payload) => ({
    type: actions.SET_MERCHANT_USER_DATA,
    payload: payload,
  }),
  updateMerchantUser: (payload) => ({
    type: actions.UPDATE_MERCHANT_USER,
    payload,
  }),
  fetchAllFilteredRole: () => ({
    type: actions.FETCH_ALL_FILTERED_ROLE,
  }),
  fetchAuthManagementRole: () => ({
    type: actions.FETCH_AUTH_MANAGEMENT_ROLE,
  }),
  resetRolePermissionData: () => ({
    type: actions.RESET_ROLE_PERMISSION_DATA,
  }),
  // fetchRolePermission: (payload) => ({
  //   type: actions.FETCH_ROLE_PERMISSION,
  //   payload,
  // }),
  // toggleRolePermissionDialog: () => ({
  //   type: actions.OPEN_ROLE_PERMISSION_DIALOG,
  // }),
  addMerchantUser: (payload) => ({
    type: actions.ADD_MERCHANT_USER,
    payload,
  }),
  deactivateMerchantUser: (payload) => ({
    type: actions.DEACTIVATE_MERCHANT_USER,
    payload,
  }),
  resetAddUserForm: () => ({
    type: actions.RESET_ADD_USER_FORM,
  }),
  resetEditUserForm: (payoad) => ({
    type: actions.RESET_EDIT_USER_FORM,
  }),
  resetPassword: (payload) => ({
    type: actions.RESET_MERCHANT_USER_PASSWORD,
    payload: payload,
  }),
  // NEW RESET PASSWORD
  newResetPassword: (payload) => ({
    type: actions.NEW_RESET_MERCHANT_USER_PASSWORD,
    payload,
  }),
  newResetPasswordReset: () => ({
    type: actions.NEW_RESET_MERCHANT_USER_PASSWORD_RESET,
  }),
  resetDeleteUser: () => ({
    type: actions.DEACTIVATE_MERCHANT_USER_RESET,
  }),
  resetPasswordReset: () => ({
    type: actions.RESET_MERCHANT_USER_PASSWORD_RESET,
  }),
  getAllPchannels: () => ({
    type: actions.GET_ALL_PCHANNELS,
  }),
  editMerchantDetails: (payload) => ({
    type: actions.EDIT_MERCHANT_DETAILS,
    payload: payload,
  }),
  resetMessageNotif: () => ({
    type: actions.RESET_MESSAGE_NOTIF,
  }),
  downloadHistory: (payload) => ({
    type: actions.DOWNLOAD_HISTORY,
    payload: payload,
  }),
  resetDownload: () => ({
    type: actions.RESET_DOWNLOAD_HISTORY,
  }),
  downloadStoreTxn: (payload) => ({
    type: actions.DOWNLOAD_STORE_TXN,
    payload: payload,
  }),
  resetDownloadedStoreTxn: () => ({
    type: actions.RESET_DOWNLOADED_STORE_TXN,
  }),
  fetchRiskProfile: () => ({
    type: actions.FETCH_RISK_PROFILE,
  }),
  getKycQualified: () => ({
    type: actions.GET_KYC_QUALIFIED,
  }),
  submitKycDocs: (payload) => ({
    type: actions.POST_KYC_DOCS,
    payload: payload,
  }),
  resetKycPost: () => ({
    type: actions.RESET_KYC_POST,
  }),
  previewKycDocs: (payload) => ({
    type: actions.PREVIEW_KYC_DOCS,
    payload: payload,
  }),
  resetPreview: () => ({
    type: actions.RESET_KYC_PREVIEW,
  }),
  reloadKyc: () => ({
    type: actions.RELOAD_KYC,
  }),
  fetchMerchantReferralLink: (payload) => ({
    type: actions.FETCH_MERCHANT_REFERRAL_LINK,
    payload: payload,
  }),
  uploadLogo: (payload) => ({
    type: actions.UPLOAD_MERCHANT_LOGO,
    payload: payload,
  }),
  resetLogoUpload: () => ({
    type: actions.RESET_MERCHANT_LOGO_UPLOAD,
  }),
  askForReupload: (payload) => ({
    type: actions.ASK_FOR_REUPLOAD,
    payload: payload,
  }),
  resetAskReupload: () => ({
    type: actions.RESET_ASK_REUPLOAD,
  }),
  approveKycDocuments: () => ({
    type: actions.APPROVE_KYC_DOCS,
  }),
  resetApproving: () => ({
    type: actions.RESET_APPROVING,
  }),
  updateMerchantChannels: (payload) => ({
    type: actions.UPDATE_MERCHANT_CHANNELS,
    payload: payload,
  }),
  fetchMerchantImage: (payload) => ({
    type: actions.FETCH_MERCHANT_IMAGE,
    payload,
  }),
  fetchCustomerData: (payload) => ({
    type: actions.FETCH_CUSTOMER_DATA,
    payload,
  }),
  paymentTransactionRequest: (payload) => ({
    type: actions.PAYMENT_TRANSACTION_REQUEST,
    payload,
  }),
  resetPaymentTransactionRequest: () => ({
    type: actions.RESET_PAYMENT_TRANSACTION_REQUEST,
  }),
  resetTokenData: (payload) => ({
    type: actions.RESET_TOKEN_DATA,
    payload,
  }),
};
export default actions;
