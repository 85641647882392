import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Transactions from '@iso/redux/transactions/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import MqttReducer from '@iso/redux/mqtt/reducer';
import Dashboard from '@iso/redux/dashboard/reducer';
import Merchant from '@iso/redux/merchant/reducer';
import Inventory from '@iso/redux/inventory/reducer';
import Financials from '@iso/redux/financials/reducer';
import Store from '@iso/redux/store/reducer';
import Settings from '@iso/redux/settings/reducer';


export default combineReducers({
  Auth,
  App,
  Ecommerce,
  Inventory,
  Financials,
  Merchant,
  MqttReducer,
  LanguageSwitcher,
  ThemeSwitcher,
  Transactions,
  Dashboard,
  Store,
  Settings,
});
