import actions from './actions';
import authActions from '../auth/actions';
// import dummyInventory from './dummyData'

const initState = {
  errorMessage: null,
  message: null,
  loadingBalances: false,
  loadingLimit: false,
  walletDetails: null,
  limitDetails: null,
  loadingBankAccounts: false,
  bankAccounts: null,
  isRequestOtp: false,
  otpMessage: null,
  isErrorOtp: false,
  isVerifyOtp: false,
  verifyOtpMessage: null,
  isErrorVerifyOtp: false,
  resendSuccessMessage: null,
  isResendOtp: false,
  loadingSupportedBanks: false,
  supportedBanks: null,
  loadingSendingOtp: false,
  otpSent: false,
  loadingSavingBank: false,
  savingBankResult: null,
  loadingResendingOtp: false,
  resendOtpMessage: null,
  loadingUpdatingBank: false,
  updatedBankResult: false,
  updateSuccess: false,
  loadingRemovingBank: false,
  removedBankResult: false,
  loadingChangingFavorite: false,
  removeSuccess: false,
  changingFailed: false,
  withdrawalData: null,
  isGetWithdrawal: false,
  isErrorWithrawalRequest: false,
  emailNotif: null,
  downloadingWithdrawal: false,
  downloadedWithdrawalData: null,
  downloadWithdrawalMessage: null,
  withdrawalRequestData: null,
  updateStatusBoolean: false,
  updatedData: null,
  changingStatusFailed: false,
  changingStatusSuccess: false,
  loadingChangingStatus: false,
  modules: [
    {
      id: 'wallet',
      label: 'Biz.financials.menu.wallet',
      view: 'BizWallet'
    },
    {
      id: 'bankAccounts',
      label: 'Biz.financials.menu.banks',
      view: 'EnrolledBankAccounts'
    },
    // {
    //   id: 'bookeeping',
    //   label: 'Biz.financials.menu.bookeeping',
    //   view: 'Bookeeping'
    // },
    // {
    //   id: 'reports',
    //   label: 'Biz.financials.menu.reports',
    //   view: 'TransactionReports'
    // },
  ],
};

export default (state = initState, action) => {
  // if (action.type !== 'RESET_SECOND_TIMER') console.log('REDUCER TRIGGERED', action)

  switch (action.type) {
    case actions.GET_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        withdrawalData: action.payload,
      };
    case actions.GETTING_WITHDRAWAL_REQUEST:
      return {
        ...state,
        isGetWithdrawal: action.payload,
      };
    case actions.GET_WITHDRAWAL_REQUEST_FAILED:
      return {
        ...state,
        isGetWithdrawal: false,
      };
    case actions.GET_WITHDRAWAL_REQUEST_ERROR:
      return {
        ...state,
        isErrorWithrawalRequest: action.payload,
      };
    case actions.LOADING_BALANCES:
      return {
        ...state,
        loadingBalances: true,
      };
    case actions.LOADING_LIMIT:
      return {
        ...state,
        loadingLimit: true,
      };
    case actions.BALANCES_LOADED:
      return {
        ...state,
        loadingBalances: false,
        walletDetails: action.walletDetails,
      };
    case actions.LIMIT_LOADED:
      return {
        ...state,
        loadingLimit: false,
        limitDetails: action.limitDetails,
      };
    case actions.LOADING_BANK_ACCOUNTS:
      return {
        ...state,
        loadingBankAccounts: true,
      };
    case actions.BANK_ACCOUNTS_LOADED:
      return {
        ...state,
        loadingBankAccounts: false,
        bankAccounts: action.bankAccounts,
      };
    case actions.LOADING_BANK_ACCOUNTS_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        bankAccounts: null,
      };
    case actions.LOADING_LIMIT_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        limitDetails: null,
      };
    case actions.LOADING_BALANCES_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        walletDetails: null,
      };
    case actions.CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        errorMessage: null,
      };
    case actions.REQUESTING_OTP:
      return {
        ...state,
        isRequestOtp: action.payload,
      };
    case actions.REQUEST_OTP_FAILED:
      return {
        ...state,
        isRequestOtp: false,
      };
    case actions.REQUESTED_OTP:
      return {
        ...state,
        otpMessage: action.payload,
        isErrorOtp: action.isErrorOtp,
      };
    // VERIFY OTP
    case actions.VERIFIED_OTP:
      // console.log('VERIFIED_OTP', action);
      return {
        ...state,
        verifyOtpMessage: action.payload,
        isErrorVerifyOtp: action.isErrorVerifyOtp,
      };
    case actions.RESET_VERIFIED_OTP:
      return {
        ...state,
        verifyOtpMessage: null,
        isErrorVerifyOtp: false,
      };
    case actions.VERIFYING_OTP:
      return {
        ...state,
        isVerifyOtp: action.payload,
      };
    case actions.VERIFY_OTP_FAILED:
      return {
        ...state,
        isVerifyOtp: false,
      };
    case actions.LOADING_SUPPORTED_BANKS:
      return {
        ...state,
        loadingSupportedBanks: true,
      };
    case actions.SUPPORTED_BANK_LOADED:
      return {
        ...state,
        loadingSupportedBanks: false,
        supportedBanks: action.supportedBanks,
      };
    case actions.LOADING_SUPPORTED_BANKS_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        loadingSupportedBanks: false,
        supportedBanks: null,
      };
    case actions.SENDING_SMS_OTP:
      return {
        ...state,
        loadingSendingOtp: true,
        otpSent: false,
      };
    case actions.SMS_OTP_SENT:
      return {
        ...state,
        loadingSendingOtp: false,
        otpSent: true,
        otpMessage: 'OTP Sent',
      };
    case actions.SENDING_SMS_OTP_FAILED:
      return {
        ...state,
        loadingSendingOtp: false,
        otpSent: false,
        otpMessage: 'NO OTP RECIPIENT',
      };
    // case actions.RESET_OTP:
    //   return {
    //     ...state,
    //     loadingSendingOtp: false,
    //     otpSent: false,
    //     otpMessage: null,
    //   };
    case actions.SAVING_BANK_ACCOUNT:
      return {
        ...state,
        loadingSavingBank: true,
      };
    case actions.BANK_ACCOUNT_SAVED:
      return {
        ...state,
        loadingSavingBank: false,
        savingBankResult: action.result,
        bankAccounts: action.bankAccounts,
      };
    case actions.SAVING_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loadingSavingBank: false,
        savingBankResult: null,
      };
    // case actions.RESET_SAVING_NOTIF:
    //   return {
    //     ...state,
    //     loadingSavingBank: false,
    //     savingBankResult: null,
    //   };
    case actions.RESENDING_OTP:
      return {
        ...state,
        isResendOtp: action.payload,
      };
    case actions.RESEND_OTP_SENT:
      return {
        ...state,
        loadingResendingOtp: false,
        resendOtpMessage: action.message,
      };
    case actions.RESENDING_OTP_FAILED:
      return {
        ...state,
        loadingResendingOtp: false,
        resendOtpMessage: action.message,
      };
    case actions.W_RESET_RESEND_OTP:
      return {
        ...state,
        resendSuccessMessage: null,
        isErrorResendOtp: false,
      };
    case actions.W_RESENDING_OTP:
      return {
        ...state,
        isResendOtp: action.payload,
      };
    case actions.W_RESEND_OTP_SUCCESS:
      return {
        ...state,
        resendSuccessMessage: action.payload,
        isErrorResendOtp: action.isErrorResendOtp,
      };
    case actions.W_RESEND_OTP_FAILED:
      return {
        ...state,
        isResendOtp: false,
        loadingResendingOtp: true,
      };
    case actions.UPDATING_BANK_ACCOUNT:
      return {
        ...state,
        loadingUpdatingBank: true,
      };
    case actions.BANK_ACCOUNT_UPDATED:
      return {
        ...state,
        loadingUpdatingBank: false,
        updatedBankResult: true,
        bankAccounts: action.bankAccounts,
        updateSuccess: true,
      };
    case actions.UPDATING_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loadingUpdatingBank: false,
        updatedBankResult: true,
        updateSuccess: false,
      };
    case actions.RESET_MESSAGE_NOTIF:
      return {
        ...state,
        loadingResendingOtp: false,
        resendOtpMessage: null,
        loadingSavingBank: false,
        savingBankResult: null,
        loadingSendingOtp: false,
        otpSent: false,
        otpMessage: null,
        loadingUpdatingBank: false,
        updatedBankResult: false,
        updateSuccess: false,
        loadingRemovingBank: false,
        removedBankResult: false,
        removeSuccess: false,
        changingFailed: false,
        loadingChangingFavorite: false,
        changingStatusFailed: false,
        changingStatusSuccess: false,
        loadingChangingStatus: false,
      };
    case actions.REMOVING_BANK_ACCOUNT:
      return {
        ...state,
        loadingRemovingBank: true,
      };
    case actions.BANK_ACCOUNT_REMOVED:
      return {
        ...state,
        loadingRemovingBank: false,
        removedBankResult: true,
        bankAccounts: action.bankAccounts,
        removeSuccess: true,
      };
    case actions.REMOVING_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loadingRemovingBank: false,
        removedBankResult: true,
        removeSuccess: false,
      };
    case actions.CHANGING_FAVORITE:
      return {
        ...state,
        loadingChangingFavorite: true,
      };
    case actions.FAVORITE_CHANGED:
      return {
        ...state,
        loadingChangingFavorite: false,
        bankAccounts: action.bankAccounts,
      };
    case actions.CHANGING_FAVORITE_FAILED:
      return {
        ...state,
        loadingChangingFavorite: false,
        changingFailed: true,
      };
    case actions.CHANGING_STATUS:
      return {
        ...state,
        loadingChangingStatus: true,
      };
    case actions.STATUS_CHANGED:
      return {
        ...state,
        bankAccounts: action.bankAccounts,
        loadingChangingStatus: false,
        changingStatusSuccess: true,
      };
    case actions.CHANGING_STATUS_FAILED:
      return {
        ...state,
        changingStatusFailed: true,
        loadingChangingStatus: false,
      };
    case actions.EMAIL_NOTIF:
      return {
        ...state,
        emailNotif: action.payload,
        // changingFailed: true,
      };
    case actions.DOWNLOAD_WITHDRAWAL_LOADING:
      return {
        ...state,
        downloadingWithdrawal: true,
      };
    case actions.DOWNLOAD_WITHDRAWAL_LOADED:
      return {
        ...state,
        downloadedWithdrawalData: action.payload,
        downloadingWithdrawal: false,
      };
    case actions.DOWNLOAD_WITHDRAWAL_MESSAGE:
      return {
        ...state,
        downloadWithdrawalMessage: action.payload,
        downloadedWithdrawalData: null,
        downloadingWithdrawal: false,
      };
    case actions.FETCH_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        withdrawalRequestData: action.payload,
      };
    case actions.FETCH_WITHDRAWAL_REQUEST_FAILED:
      return {
        ...state,
        withdrawalRequestData: action.payload,
      };
    case actions.UPDATING_WITHDRAWAL_STATUS:
      return {
        ...state,
        updateStatusBoolean: action.payload,
      };
    case actions.UPDATED_WITHDRAWAL_STATUS:
      return {
        ...state,
        updateData: action.payload,
      };
    case actions.UPDATING_FAILED_WITHDRAWAL_STATUS:
      return {
        ...state,
        updateStatusBoolean: false,
      };
    case authActions.LOGOUT:
      // reset all redux param values
      return initState;
    default:
      return state;
  }
}